<template>
  <v-main>
    <MaseList :mases="mases" v-if="!loading" />
    <LoadingIndicator v-if="loading" />
    <v-btn
      to="/addmase"
      color="success"
      dark
      fixed
      bottom
      right
      fab
      class="mb-8 d-sm-none"
    >
      <v-icon>fa-plus</v-icon>
    </v-btn>
  </v-main>
</template>

<script>
// import axios from "axios";
import { getAllMases } from "@/util/APIUtils";
import { showError, showMessage } from "@/util/MASEUtils";
import MaseList from "../components/MaseList.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
// import testdata from "../testdata/testMases.json";

export default {
  components: { MaseList, LoadingIndicator },
  data() {
    return {
      section: "home",
      // mases: 'testdata.mases',
      mases: [],
      loading: true,
      drawer: null,
    };
  },
  methods: {
    fetchMases() {
      this.loading = true;
      getAllMases().then((response) => {
        if (!response.error) {
          this.mases = response.data;
          this.loading = false;
        } else {
          showError(this, "Failed to load mases, please retry later!");
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.loading = true;
    this.fetchMases();
  },
  updated() {
    const url = new URL(window.location.href);
    const s = url.searchParams.get("success");
    if (s === "true") {
      showMessage(this, "succeeded!");
    }
  },
};
</script>

<style lang="scss" scoped>
#loading {
  // height: 100%;
}
#loading .md-list {
  // background-color: transparent;
}
</style>
