<template>
  <div v-if="maseSolution" class="ma-0 pa-0">
    <!--   <v-row class="ma-0 pa-0 align-start pl-4 pr-2 pt-0" no-gutters>-->
    <!--      <v-col class="pl-4 pr-2 ">-->
    <v-row class="ma-0 pa-0 mr-n3">
      <v-col class="pa-0 ma-0">
        <p
          class="text-body-1 font-weight-normal black--text pa-0 ma-0 pt-3"
          :inner-html.prop="maseSolution.description"
        ></p>
      </v-col>
      <v-col cols="auto" class="pa-0 ma-0">
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey darken-4"
              class="ma-0 white--text"
              elevation="3"
              fab
              x-small
              v-bind="attrs"
              v-on="on"
              v-if="isEditable()"
            >
              <v-icon dark size="10"> fas fa-pencil-alt </v-icon>
            </v-btn>
          </template>
          <v-container class="pa-0 ma-0">
            <MaseSolutionForm
              v-if="dialog"
              :maseId="maseId"
              :initialMaseSolution="maseSolution"
              :masePostDone="masePostDone"
            ></MaseSolutionForm>
          </v-container>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row v-if="maseSolution.medias" class="pa-0 ma-0 mx-n3">
      <v-col
        v-for="image in maseSolution.medias"
        :key="image.id"
        class="d-flex child-flex"
        cols="auto"
      >
        <v-sheet outlined elevation="2" flat class="pa-0 ma-0 mb-2">
          <a v-if="productUrl" :href="productUrl" target="_blank">
            <v-img
              contain
              :src="getDefaultMediaUrlFromMedia(image)"
              class="fill-height ma-2"
              v-if="isImage(image.mediaType)"
            />
          </a>
          <v-img
            contain
            :src="getDefaultMediaUrlFromMedia(image)"
            class="ma-2"
            v-if="!productUrl && isImage(image.mediaType)"
          />

          <Media
            :ref="'video_player'"
            :kind="'video'"
            v-if="isVideo(image.mediaType)"
            :controls="true"
            :src="[getDefaultMediaUrlFromMedia(image)]"
            :style="{
              width: '100%',
              height: '100%',
              'background-color': 'black',
            }"
            :autoplay="false"
            :loop="true"
          >
          </Media>
          <!--
          <VideoPlayer v-if="isVideo(image.mediaType)"> </VideoPlayer>
          -->
        </v-sheet>
      </v-col>
    </v-row>

    <v-row
      class="mx-0 mb-3 mt-0"
      v-if="maseSolution.product && maseSolution.product.title"
    >
      <v-col class="d-flex child-flex" cols="auto">
        <a
          :href="productUrl"
          target="_blank"
          class="text-decoration-none"
          :title="maseSolution.product.description"
        >
          <ProductLine v-bind:product="maseSolution.product" />
        </a>
      </v-col>
    </v-row>

    <!--
    <v-row class="pa-0 ma-0">
      <p class="text-h6 font-weight-black" v-if="productUrl">
        <a :href="productUrl" target="_blank">Link zum Produkt</a>
      </p>
    </v-row>
    -->
    <v-row class="pa-0 ma-0">
      <v-list-item class="grow mt-auto ma-0 pa-0 pb-3">
        <v-list-item-content class="pa-0 ma-0">
          <v-list-item-title class="text-body-2 font-regular pa-0 ma-0"
            ><v-list-item-avatar
              size="25"
              color="grey darken-3"
              class="ma-2 pa-0 ml-0"
            >
              <v-img
                :src="maseSolution.author.avatarUrl"
              ></v-img> </v-list-item-avatar
            >{{ maseSolution.author.nickname }}
            <span class="ml-1 text-body-2 font-weight-light"
              >at {{ dateToString(maseSolution.createdAt) }}
            </span></v-list-item-title
          >
          <v-list-item-title class="pa-0 ma-0 py-1"
            ><MaseVote v-if="true" :mase="maseSolution" maseType="solution" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-row>
    <v-row class="pa-0 ma-0 mx-n4">
      <v-divider v-if="!last" class="ma-0 pa-0 mb-2"></v-divider>
    </v-row>
  </div>
</template>

<script>
import Media from "@dongido/vue-viaudio";
import MaseVote from "@/components/MaseVote.vue";
import MaseSolutionForm from "@/components/form/MaseSolutionForm.vue";
import { getDefaultMediaUrlFromMedia } from "@/util/MASEUtils";
import ProductLine from "@/components/ProductLine.vue";
// import VideoPlayer from "@/components/video/VideoPlayer.vue";

export default {
  components: {
    MaseVote,
    MaseSolutionForm,
    Media,
    ProductLine,
    // VideoPlayer,
  },
  computed: {
    productUrl() {
      return this.maseSolution.affiliateLink
        ? this.maseSolution.affiliateLink
        : this.maseSolution.originalProductLink;
    },
  },
  data() {
    return {
      showmessage: false,
      showerror: false,
      messagetext: "Default text",
      maseSolution: null,
      solutionUpload: {
        defaultUploadText: "Upload a Image",
        selectedFile: null,
        isSelecting: false,
      },
      solutionDescription: {
        outlined: false,
        value: "",
        placeholder: "Enter here the Description of the solution",
      },
      solutionLink: {
        error: false,
        value: "",
        placeholder: "Enter the link to the product",
      },
      dialog: false,
    };
  },
  props: {
    maseSolutionParam: {
      required: true,
    },
    maseId: {
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    last: {
      type: Boolean,
      required: false,
    },
  },
  mounted() {
    this.maseSolution = this.$props.maseSolutionParam;
  },
  methods: {
    getDefaultMediaUrlFromMedia,
    masePostDone(masePosted) {
      console.info("masePostDone", masePosted);
      this.dialog = false;
      this.maseSolution = masePosted;
    },
    isEditable() {
      const author = this.$store.state.userLoggedIn;
      if (author.role === "ROLE_ADMIN") {
        return true;
      }
      return this.maseSolution.author.id === author.id;
    },
    dateToString(date) {
      const parsed = new Date(date);
      return parsed.toLocaleDateString();
    },
    isVideo(mediaType) {
      return mediaType && mediaType.includes("video");
    },
    isImage(mediaType) {
      return !mediaType || mediaType.includes("image");
    },
  },
};
</script>
