<template>
  <v-footer app class="px-2" absolute>
    <div class="font-weight-black pl-2">
      &copy; {{ new Date().getFullYear() }} Rip Mase
    </div>
    <span class="px-2">|</span>
    <a
      :href="
        $router.resolve({
          name: 'static',
          params: { id: 'privacy-policy' + '-' + this.$i18n.locale },
        }).href
      "
      >{{ $t("FOOTER_PRIVACY") }}</a
    >
    <span class="px-2">|</span>
    <a
      :href="
        $router.resolve({
          name: 'static',
          params: { id: 'imprint' + '-' + this.$i18n.locale },
        }).href
      "
      >{{ $t("FOOTER_IMPRESSUM") }}</a
    >
    <span class="px-2">|</span>
    <a
      :href="
        $router.resolve({
          name: 'static',
          params: { id: 'disclaimer' + '-' + this.$i18n.locale },
        }).href
      "
      >{{ $t("FOOTER_DISCLAIMER") }}</a
    >
    <v-spacer />
    <v-btn @click.stop="onDarkmode()">
      <v-icon v-if="!$vuetify.theme.dark">fa-moon</v-icon>
      <v-icon v-if="$vuetify.theme.dark">fa-sun</v-icon>
    </v-btn>
    <!-- -->
  </v-footer>
</template>

<script>
export default {
  methods: {
    onDarkmode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
