<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar size="38" v-bind="attrs" v-on="on" @click="onLoginClick">
        <v-icon x-large>fas fa-user-circle</v-icon>
      </v-avatar>
    </template>
    <v-container class="pa-0 ma-0">
      <v-form class="pa-0 ma-0">
        <v-card class="mx-auto">
          <v-card-title class="justify-center">{{
            $t("LOGIN_TITLE")
          }}</v-card-title>
          <v-card-text class="mx-auto text-center">
            <v-btn x-large :href="goggle" class="red mt-3" width="400px">
              <v-icon large left>fab fa-google</v-icon>
              <v-spacer />
              {{ $t("LOGIN_GOOGLE") }}<v-spacer
            /></v-btn>

            <v-btn disabled x-large :href="fb" class="blue mt-3" width="400px">
              <v-icon large left>fab fa-facebook</v-icon>
              <v-spacer />
              {{ $t("LOGIN_FACEBOOK") }}<v-spacer
            /></v-btn>

            <v-btn
              disabled
              x-large
              :href="github"
              class="grey mt-3 mb-5"
              width="400px"
            >
              <v-icon large left>fab fa-github</v-icon>
              <v-spacer />
              {{ $t("LOGIN_GITHUB") }}<v-spacer
            /></v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-container>
  </v-dialog>
</template>

<style lang="css" scoped></style>

<script>
import {
  GOOGLE_AUTH_URL,
  FACEBOOK_AUTH_URL,
  GITHUB_AUTH_URL,
} from "@/constants";

export default {
  components: {},
  computed: {},
  data() {
    return {
      dialog: false,
      username: "",
      fb: FACEBOOK_AUTH_URL,
      goggle: GOOGLE_AUTH_URL,
      github: GITHUB_AUTH_URL,
    };
  },
  methods: {
    onLoginClick() {
      this.setupLoginUrls(window.location.href);
    },
    open(aCallbackUrl) {
      this.setupLoginUrls(aCallbackUrl);
      this.dialog = true;
    },
    setupLoginUrls(aCallbackUrl) {
      this.goggle = GOOGLE_AUTH_URL + aCallbackUrl;
      this.fb = FACEBOOK_AUTH_URL + aCallbackUrl;
      this.github = GITHUB_AUTH_URL + aCallbackUrl;
    },
  },
  mounted() {
    this.$root.$on("openLogin", () => {
      this.dialog = true;
      this.onLoginClick();
    });
  },
};
</script>
