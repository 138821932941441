<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <v-card
            class="mx-auto my-3 pb-5"
            outlined
            elevation="2"
            v-if="!loading"
          >
            <v-card-title class="mb-0 pb-0 text-h5">{{
              $t("PROBLEM_FORM_EDIT_LABEL")
            }}</v-card-title>
            <v-responsive max-width="1200px">
              <v-container class="my-0 py-0">
                <MaseProblemForm ref="mf" />
              </v-container>
            </v-responsive>
          </v-card>
          <LoadingIndicator v-if="loading" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { getMase } from "@/util/APIUtils";
import { showError } from "@/util/MASEUtils";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MaseProblemForm from "@/components/form/MaseProblemForm.vue";

export default {
  components: { MaseProblemForm, LoadingIndicator },
  data() {
    return {
      maseToEdit: {},
      maseId: this.$route.params.id,
      loading: true,
      // previewmase: { title: "", description: "" },
    };
  },
  computed: {},
  methods: {
    onMaseLoaded(mase) {
      console.info("onMaseLoaded", this.$refs.mf);
      this.$refs.mf.maseLoaded(mase);
    },
  },
  mounted() {
    getMase(this.maseId).then((response) => {
      if (!response.error) {
        this.maseToEdit = response.data;
      } else {
        showError(this, this.$t("ERROR_FAILED_TO_LOAD_MASE"));
      }
      this.loading = false;
      this.$nextTick(() => this.onMaseLoaded(this.maseToEdit));
    });
  },
};
</script>
<style lang="css" scoped>
.preview {
  max-width: 600px;
}
</style>
