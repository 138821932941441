import fileHelpers from './FileUtils';
import { getDefaultMediaUrlFromMedia } from "@/util/MASEUtils";

export default class FileUpload {
    fromFile(file) {
        this.file = file;
        this.filesize = file.size;
        this.formattedFilesize = fileHelpers.fileSize(file.size);
        this.error = null;
        this.percentageUploaded = 0;
        this.uploadedParts = [];
        this.totalParts = 1;
        this.extension = fileHelpers.fileExtension(file.name);
        this.isUploading = false;
        this.fromServer = false;
        this.progressStatus = null;
        this.type = file.type;
    }

    fromMaseMedia(media) {
        this.filesize = media.mediaSize;
        this.formattedFilesize = fileHelpers.fileSize(media.mediaSize);
        this.fromServer = true;
        this.url = getDefaultMediaUrlFromMedia(media);
        this.id = media.id;
        this.type = media.mediaType;
        this.progressStatus = null;
        this.percentageUploaded = 0;
        this.uploadedParts = [];
        this.totalParts = 1;
        this.isUploading = false;
    }

    /**
     * @param {*} status "queued" or "uploading" or null
     */
    setProgressStatus(status) {
        this.progressStatus = status;
    }

    setProgress(progressEvent) {
        this.percentageUploaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    }

    setMultipartProgress(progressEvent, totalParts, currentPart) {
        this.totalParts = totalParts;
        this.addPart(currentPart);
        const index = this.uploadedParts.findIndex((part) => part.part === currentPart);
        this.uploadedParts[index].loaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.info("setMultipartProgress:", this.multipartUploadPercent);
        return this.multipartUploadPercent;
    }

    addPart(part) {
        if (this.uploadedParts.indexOf(part) === -1) {
            this.uploadedParts.push({
                part,
                loaded: 0
            });
        }
    }

    get singlepartUploadPercent() {
        return this.percentageUploaded;
    }

    get multipartUploadPercent() {
        const partSize = 100 / this.totalParts;
        const percent = this.uploadedParts.reduce((acc, val) => {
            return acc + ((val.loaded * partSize) / 100);
        }, 0);
        return percent;
    }
}
