// export const API_BASE_URL = 'http://localhost:8080';
export const API_BASE_URL = 'https://api.mase.rip';
// export const PDP_BASE_URL = 'http://localhost:8080';
export const PDP_BASE_URL = 'https://pdp.mase.rip';

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=';
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=';
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=';
export const GET_ALL_MASES_URL = API_BASE_URL + '/maseproblem/public/all';
export const GET_MASE_URL = API_BASE_URL + '/maseproblem/public/';
export const UPDATE_MASE_URL = API_BASE_URL + '/maseproblem/update/';
export const LOGIN_URL = API_BASE_URL + '/customer/public/login';
export const USERDETAILS_URL = API_BASE_URL + '/customer/userinfo';
export const SIGN_UP_URL = API_BASE_URL + '/customer/public/signup';
export const UPDATE_USERDETAILS_URL = API_BASE_URL + '/customer/userinfo';
export const CREATE_MASE_URL = API_BASE_URL + '/maseproblem/create';
export const DELETE_MASE_SOLUTION_URL = API_BASE_URL + '/masesolution/delete/';
export const DELETE_MASE_URL = API_BASE_URL + '/maseproblem/delete/';
export const CREATE_MASE_SOLUTION_URL = API_BASE_URL + '/masesolution/create';
export const UPLOAD_MASE_MEDIA_URL = API_BASE_URL + '/maseproblem/uploadMediaToMase/';
export const UPLOAD_MASE_SOLUTION_MEDIA_URL = API_BASE_URL + '/masesolution/uploadMediaToMase/';

export const GET_PROBLEMS_BY_QUERY = API_BASE_URL + '/maseproblem/public/search/';
export const GET_USER_IP_DETAILS = API_BASE_URL + '/userip/public/userip';
export const RATE_MASE_PROBLEM = API_BASE_URL + '/maseproblem/public/rate';
export const VOTE_MASE_PROBLEM = API_BASE_URL + '/maseproblem/public/vote';
export const VOTE_MASE_SOLUTION = API_BASE_URL + '/masesolution/public/vote';
export const UPDATE_MASE_SOLUTION_URL = API_BASE_URL + '/masesolution/update/';

export const LOCAL_STORAGE_ACCESS_TOKEN = 'accessToken';
export const LOCAL_STORAGE_LOGGED_IN_USER = 'loggedInUser';
export const LOCAL_STORAGE_USERRATES = 'userrates';
export const LOCAL_STORAGE_USERVOTES = 'uservotes';
export const LOCAL_STORAGE_LOCALE = "locale";
export const API_DEFAULT_NO_OF_RETRIES = 2;

export const GET_ALL_TAGS_URL = API_BASE_URL + '/masetag/public/all';
export const GET_TAG_BY_ID = API_BASE_URL + '/masetag/public/';

export const STATIC_PAGE_URL = 'https://cms.mase.rip/wp-json/wp/v2/pages/?slug=';

export const MUILTIPART_CHUNK_SIZE = 1024 * 1024 * 10; // 10mb

export const GET_PDP_URL = PDP_BASE_URL + '/pdp/';
export const CANCELED = "CANCELED";
