/* eslint-disable */
import {
    createNewMaseGeneric, updateMaseGeneric
} from './APIUtils';

export function showMessage(vue, message) {
    vue.$root.$emit("message", message);
}

export function showError(vue, message) {
    vue.$root.$emit("error", message);
}

export function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'// protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'// domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))'// OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*');// port and path
    return !!pattern.test(str);
}

export function validEmail(str) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

export function editMase(maseEndpointURL, maseMediaEndpointURL, maseToEdit, imageUploader) {
    maseToEdit.medias = imageUploader.initialMedia;
    console.info("editMase content", maseToEdit);
    console.info("editMase media", imageUploader.files);
    return new Promise((resolve, reject) => {
        updateMaseGeneric(maseEndpointURL, maseToEdit).then((result) => {
            if (result.error) {
                reject(new Error(result.error));
            } else {
                const mase = result.data;
                if (imageUploader.files && imageUploader.files.length > 0) {
                    imageUploader.handleMediaUpload(maseMediaEndpointURL, mase, resolve, reject)
                } else {
                    resolve(mase);
                }
            }
        });
    });
}

export function createMase(maseEndpointURL, maseMediaEndpointURL, maseToCreate, imageUploader) {
    console.info("createMase content", maseToCreate);
    console.info("createMase media", imageUploader.files);
    return new Promise((resolve, reject) => {
        // Posting Mase
        createNewMaseGeneric(maseEndpointURL, maseToCreate).then((result) => {
            if (result.error) {
                reject(new Error(result.error));
            } else {
                const mase = result.data;
                if (imageUploader.files && imageUploader.files.length > 0) {
                    imageUploader.handleMediaUpload(maseMediaEndpointURL, mase, resolve, reject)
                } else {
                    resolve(mase);
                }
            }
        });
    });
}

export function getDefaultMediaUrlFromMedia(media) {
    if (media.convertedMedias) {
        if (media.convertedMedias.HIGH) {
            return media.convertedMedias.HIGH.mediaUrl;
        }
        if (media.convertedMedias.MEDIUM) {
            return media.convertedMedias.MEDIUM.mediaUrl;
        }
        if (media.convertedMedias.LOW) {
            return media.convertedMedias.LOW.mediaUrl;
        }
    } else {
        return media.mediaUrl;
    }
}

export function toSeoUrl(url) {
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        //.replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
}

export function fromSeoUrl(url) {
    return url.toString()               // Convert to string
        .replace(/-/g, ' ');             // dashes to spaces
}
/* eslint-enable */
