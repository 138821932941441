import axios from "axios";
import axiosRetry from 'axios-retry';
import { store, getAccessToken } from "./STATEUtils";
import i18n from '../i18n';

// import store from "../util/STATEUtils";
import {
    GET_ALL_MASES_URL,
    GET_MASE_URL,
    LOGIN_URL,
    UPDATE_MASE_URL,
    DELETE_MASE_URL,
    CREATE_MASE_SOLUTION_URL,
    UPLOAD_MASE_MEDIA_URL,
    USERDETAILS_URL,
    GET_USER_IP_DETAILS,
    RATE_MASE_PROBLEM,
    STATIC_PAGE_URL,
    UPDATE_USERDETAILS_URL,
    API_DEFAULT_NO_OF_RETRIES,
    UPDATE_MASE_SOLUTION_URL,
    DELETE_MASE_SOLUTION_URL,
    GET_TAG_BY_ID,
    GET_ALL_TAGS_URL,
    GET_PROBLEMS_BY_QUERY,
    GET_PDP_URL,
    CANCELED,
} from '../constants';

function requestInterceptor(request) {
    const req = request;
    const axiosSource = axios.CancelToken.source();
    req.cancelToken = axiosSource.token;
    store.dispatch("addRequest", { ...request, cancel: axiosSource.cancel });
    return request;
}

function responseErrorInterceptor(error) {
    if (axios.isCancel(error)) console.log("Request was cancelled");
}

const client = axios.create();
client.interceptors.request.use(requestInterceptor, responseErrorInterceptor);

export function cancelRequest() {
    store.dispatch("cancelRequest");
}

const request = (options, hideLocale) => {
    const headers = {};
    const accessToken = getAccessToken();
    if (accessToken) {
        headers.Authorization = "Bearer " + accessToken;
    }

    const locale = i18n.locale;
    if (!hideLocale && locale) {
        headers.language = locale;
    }

    const axiosoptions = { ...options, headers };

    axiosRetry(client, { retries: API_DEFAULT_NO_OF_RETRIES });

    return client.request(axiosoptions)
        .then((response) => {
            if (response.status < 300) {
                return { error: false, data: response.data, status: response.status };
            }
            return { error: true, status: response.status };
        })
        /*
        .catch((error) => ({
            error: true, status: error,
        }));
        */
        .catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message);
                return { error: true, status: CANCELED };
            }
            return { error: true, status: thrown };
        });
};

export function login(loginRequest) {
    return request({
        url: LOGIN_URL,
        method: 'POST',
        data: loginRequest,
    });
}

export function loadUser() {
    return request({
        url: USERDETAILS_URL,
        method: 'GET',
    });
}

export function updateUser(userdate) {
    return request({
        url: UPDATE_USERDETAILS_URL,
        method: 'PUT',
        data: userdate,
    });
}

export function getAllMases() {
    return request({
        url: GET_ALL_MASES_URL,
        method: 'GET',
    });
}

export function getMase(maseId) {
    return request({
        url: GET_MASE_URL + maseId,
        method: 'GET',
    });
}

export function updateMase(masedata) {
    return request({
        url: UPDATE_MASE_URL,
        method: 'PUT',
        data: masedata,
    });
}

export function updateMaseSolution(masedata) {
    return request({
        url: UPDATE_MASE_SOLUTION_URL,
        method: 'PUT',
        data: masedata,
    });
}

export function updateMaseGeneric(endpointURL, masedate) {
    return request({
        url: endpointURL,
        method: 'PUT',
        data: masedate,
    });
}

export function deleteMase(maseId) {
    return request({
        url: DELETE_MASE_URL + maseId,
        method: 'DELETE',
    });
}

export function deleteMaseSolution(maseId) {
    return request({
        url: DELETE_MASE_SOLUTION_URL + maseId,
        method: 'DELETE',
    });
}

export function createNewMaseSolition(solutiondate) {
    return request({
        url: CREATE_MASE_SOLUTION_URL,
        method: 'POST',
        data: solutiondate,
    });
}

export function createNewMaseGeneric(endpointURL, masedate) {
    return request({
        url: endpointURL,
        method: 'POST',
        data: masedate,
    });
}

export function addMaseMedia(maseId, file) {
    const formData = new FormData();
    formData.append("file", file);
    return request({
        url: UPLOAD_MASE_MEDIA_URL + maseId,
        method: 'POST',
        data: formData,
    });
}

export function getUserIpDetails() {
    return request({
        url: GET_USER_IP_DETAILS,
        method: 'GET',
    });
}

export function rateMaseProblem(maseId, rate) {
    return request({
        url: RATE_MASE_PROBLEM + '/' + maseId + "/" + rate,
        method: 'POST',
    });
}

export function voteMase(maseId, vote, base) {
    return request({
        url: base + '/' + maseId + "/" + vote,
        method: 'POST',
    });
}

export function removeVoteFromMase(maseId, base) {
    return request({
        url: base + '/' + maseId,
        method: 'DELETE',
    });
}

export function getStaticPage(pageId) {
    return request({
        url: STATIC_PAGE_URL + pageId,
        method: 'GET',
    }, true);
}

export function getTagById(tagId) {
    return request({
        url: GET_TAG_BY_ID + tagId,
        method: 'GET',
    });
}

export function getProblemsByQuery(query) {
    return request({
        url: GET_PROBLEMS_BY_QUERY + query,
        method: 'GET',
    });
}

export function getAllTags() {
    return request({
        url: GET_ALL_TAGS_URL,
        method: 'GET',
    });
}

export function getPDP(pdpUrlEncoded) {
    return request({
        url: GET_PDP_URL + pdpUrlEncoded,
        method: 'GET',
    });
}
