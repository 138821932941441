<template>
  <div>
    <v-progress-circular
      class="mr-2"
      v-if="loading"
      size="20"
      indeterminate
    ></v-progress-circular>
    <v-btn-toggle
      dense
      borderless
      class="pa-0 ma-0 rounded-0"
      v-model="toggle"
      active-class="grey lighten-1"
      @change="voteMase"
      @click.native.prevent=""
      @click.stop=""
    >
      <v-btn
        ret
        text
        small
        outlined
        elevation="2"
        @click.native.prevent=""
        @click.stop=""
      >
        <v-icon color="green darken-2" left>fas fa-arrow-alt-circle-up</v-icon>
        {{ $t("VOTE_UP") }} ({{ upCount }})
      </v-btn>
      <v-btn
        text
        small
        outlined
        elevation="2"
        class="ml-2"
        @click.native.prevent=""
        @click.stop=""
      >
        <v-icon color="red darken-2" left>fas fa-arrow-alt-circle-down</v-icon>
        {{ $t("VOTE_DOWN") }} ({{ downCount }})
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<style lang="css" scoped></style>

<script>
import { voteMase, removeVoteFromMase } from "@/util/APIUtils";
import { VOTE_MASE_PROBLEM, VOTE_MASE_SOLUTION } from "@/constants";

export default {
  computed: {},
  data: () => ({
    toggle: 1,
    upCount: 0,
    downCount: 0,
    loading: false,
    base: "",
  }),
  props: {
    mase: {
      type: Object,
      required: true,
    },
    maseType: {
      type: String,
      required: false,
      default: "problem",
    },
  },
  methods: {
    updateVotes(amase) {
      this.upCount = amase.ups ? amase.ups : 0;
      this.downCount = amase.downs ? amase.downs : 0;
    },
    voteMase() {
      if (this.toggle === undefined) {
        this.loading = true;
        removeVoteFromMase(this.mase.id, this.base).then((response) => {
          this.loading = false;
          if (!response.error) {
            const maseResponse = response.data;
            this.updateVotes(maseResponse);
            this.$store.commit({
              type: "removeVote",
              maseId: this.mase.id,
            });
          }
        });
      } else {
        let up;
        if (this.toggle === 0) {
          up = true;
        } else if (this.toggle === 1) {
          up = false;
        }
        this.loading = true;
        voteMase(this.mase.id, up, this.base).then((response) => {
          this.loading = false;
          if (!response.error) {
            const maseResponse = response.data;
            this.updateVotes(maseResponse);
            this.$store.commit({
              type: "storeVote",
              maseId: this.mase.id,
              vote: up,
            });
          }
        });
      }
    },
  },
  mounted() {
    const vote = this.$store.state.userVotes.get(this.mase.id);
    if (vote !== undefined) {
      this.toggle = vote ? 0 : 1;
    } else {
      this.toggle = undefined;
    }
    if (this.maseType === "problem") {
      this.base = VOTE_MASE_PROBLEM;
    } else {
      this.base = VOTE_MASE_SOLUTION;
    }
    this.updateVotes(this.mase);
  },
};
</script>
