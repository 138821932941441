<template>
  <v-card
    :height="usage != 'fullscreen' ? '100%' : ''"
    elevation="1"
    outlined
    class="pb-16 cart rounded-t-lg"
  >
    <v-card-title class="pa-0 ma-0 align-center">
      <v-col class="pr-10 d-flex justify-start align-end text-truncate mr-n8">
        <span class="text-truncate">{{ maseProblem.title }} </span>
      </v-col>
      <v-col cols="auto" class="d-flex pa-0 ma-0 align-center d-flex">
        <router-link
          v-if="isEditable()"
          :to="{ name: 'editmase', params: { id: this.maseProblem.id } }"
          class="text-decoration-none"
        >
          <v-btn
            color="grey darken-4"
            class="ma-2 white--text"
            elevation="3"
            fab
            x-small
          >
            <v-icon dark size="15"> fas fa-pencil-alt </v-icon>
          </v-btn>
        </router-link>

        <span
          class="subtitle-2 font-weight-light pa-0 ma-0 pr-2 mb-n1"
          v-if="mode == 'rate'"
        >
          [{{ maseProblem.totalRates }} votes]
        </span>
        <span class="d-flex pr-2" v-if="mode == 'rate'">
          <v-icon class="d-flex pb-2" size="25" color="brown darken-4" left>
            fas fa-poo
          </v-icon>
          <span> {{ Math.round(maseProblem.avgRate * 10) / 10 }} </span>
        </span>
      </v-col>
    </v-card-title>

    <v-carousel
      :cycle="!containsVideo(maseProblem.medias)"
      v-if="maseProblem.medias"
      v-model="carousel"
      :show-arrows="maseProblem.medias && maseProblem.medias.length > 1"
      :hide-delimiters="true"
      :progress="maseProblem.medias && maseProblem.medias.length > 1"
      :progress-color="
        maseProblem.medias && maseProblem.medias.length > 1
          ? 'grey lighten-2'
          : 'transparent'
      "
      :continuous="true"
      height="440px"
    >
      <!--
      @click.native="preventCarousel($event)"
      :hide-delimiters="maseProblem.medias.length < 2"
      -->
      <template v-slot:prev="{ on, attrs }">
        <v-btn
          @click.native.stop=""
          @click.native.prevent=""
          v-bind="attrs"
          v-on="on"
          icon
          ><v-icon dense large color="white" class="pr-1">
            fas fa-angle-left
          </v-icon></v-btn
        >
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
          @click.native.stop=""
          @click.native.prevent=""
          v-bind="attrs"
          v-on="on"
          icon
          ><v-icon dense large color="white" class="pl-1">
            fas fa-angle-right
          </v-icon></v-btn
        >
      </template>
      <v-sheet color="brown lighten-5" height="100%">
        <v-carousel-item v-for="media in maseProblem.medias" :key="media.id">
          <v-img
            contain
            height="440px"
            :src="getDefaultMediaUrlFromMedia(media)"
            v-if="isImage(media.mediaType)"
          />
          <Media
            :ref="'video_player'"
            :kind="'video'"
            v-if="isVideo(media.mediaType)"
            :controls="true"
            :src="[getDefaultMediaUrlFromMedia(media)]"
            :style="{
              width: '100%',
              height: '100%',
              'background-color': 'black',
            }"
            :autoplay="autoplay"
            :loop="true"
          >
          </Media>
        </v-carousel-item>
      </v-sheet>
    </v-carousel>
    <!--
    <div
      style="height: 440px"
      class="brown lighten-5"
      v-for="image in maseProblem.medias"
      :key="image.id"
    >
      <v-img
        contain
        height="440px"
        :src="image.mediaUrl"
        v-if="isImage(image.mediaType)"
      />
      <Media
        :ref="'video_player'"
        :kind="'video'"
        v-if="isVideo(image.mediaType)"
        :controls="true"
        :src="[image.mediaUrl]"
        :style="{
          width: '100%',
          height: '100%',
          'background-color': 'black',
        }"
        :autoplay="autoplay"
        :loop="true"
      >
      </Media>
    </div>
    -->
    <router-link
      v-for="tag in maseProblem.tags"
      :key="tag.id"
      :to="tagTo(tag)"
      class="text-decoration-none"
    >
      <v-btn
        :class="tagClass(tag)"
        :text="tag.id == activeTagId ? false : true"
        style=""
        >#{{ tag.text }}
      </v-btn>
    </router-link>
    <v-card-text
      v-if="usage != 'fullscreen'"
      class="text-body-1 pb-0 black--text"
      :inner-html.prop="maseProblem.description | truncate(300)"
    ></v-card-text>
    <v-card-text
      v-if="usage == 'fullscreen'"
      class="text-body-1 mb-0 pb-0 black--text"
      :inner-html.prop="maseProblem.description"
    >
    </v-card-text>
    <v-card-actions class="card-actions ma-0 pa-0">
      <v-row class="ma-0 pa-0 align-end">
        <v-col class="ma-0 pa-0 pb-1">
          <div v-if="maseProblem.author">
            <v-list-item class="grow mt-auto ma-0 pa-0 pl-2">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  :src="maseProblem.author.avatarUrl"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title class="text-body-2 font-regular pa-0 ma-0">{{
                  maseProblem.author.nickname
                }}</v-list-item-title>
                <v-list-item-title
                  class="text-body-2 font-weight-light pa-0 ma-0"
                  >at {{ dateToString(maseProblem.createdAt) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
        <v-col class="d-flex justify-end align-end ma-0 pa-0">
          <v-list dense class="ma-0 pa-0 transparent">
            <v-list-item
              class="pl-3 pt-0"
              v-if="maseProblem.solutionsCount > 0"
            >
              <v-spacer></v-spacer>
              <v-btn small class="" fab dark color="red"
                ><v-icon small dark class="ml-n1">
                  {{ maseProblem.solutionsCount }}
                </v-icon></v-btn
              >
              <span class="pl-1">{{
                $tc("MASE_PROBLEM_SOLUTIONS", maseProblem.solutionsCount)
              }}</span>
            </v-list-item>
            <v-list-item v-if="mode == 'rate'">
              <v-progress-circular
                v-if="loading"
                size="20"
                indeterminate
              ></v-progress-circular>
              <span v-if="!loading" class="text-caption pt-1 ma-0 mr-1"
                >{{ $t("MASE_PROBLEM_YOUR_VOTE") }}
              </span>

              <v-rating
                :id="maseProblem.id"
                :value="this.$store.state.userRates.get(maseProblem.id)"
                @click.native.stop=""
                @click.native.prevent=""
                @input="rateMase($event, maseProblem.id)"
                class=""
                hover
                background-color="brown lighten-5"
                color="brown darken-4"
                dense
                ripple
                full-icon="fas fa-poo"
                empty-icon="fas fa-poo"
              ></v-rating>
            </v-list-item>
            <v-list-item class="pa-0 ma-0 pb-1 pr-3" v-if="mode == 'vote'">
              <MaseVote :mase="maseProblem" />
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style lang="css" scoped>
.card-actions {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.v-btn:before {
  /*opacity: 0 !important;*/
}

.v-ripple__container {
  /*opacity: 0 !important;*/
}
</style>

<script>
// import Media from "@dongido/vue-viaudio";
import { rateMaseProblem } from "@/util/APIUtils";
import MaseVote from "@/components/MaseVote.vue";
import { getDefaultMediaUrlFromMedia } from "@/util/MASEUtils";

export default {
  components: {
    MaseVote,
    // Media,
  },
  computed: {},
  data: () => ({
    carousel: 0,
    rating: 0,
    loading: false,
  }),
  props: {
    maseProblem: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: "vote",
    },
    usage: {
      type: String,
      required: false,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeTagId: {
      type: String,
      required: false,
    },
  },
  methods: {
    getDefaultMediaUrlFromMedia,
    containsVideo(medias) {
      if (medias && medias.length > 0) {
        for (let j = 0; j < medias.length; j += 1) {
          if (this.isVideo(medias[j].mediaType)) {
            return true;
          }
        }
      }
      return false;
    },
    tagTo(tag) {
      if (tag.id === this.activeTagId) {
        return { name: "home" };
      }
      return { name: "tag", params: { id: tag.id } };
    },
    tagClass(tag) {
      let classString = "rounded-0";
      if (tag.id === this.activeTagId) {
        classString += " primary lighten-1";
      }
      return classString;
    },
    isEditable() {
      const author = this.$store.state.userLoggedIn;
      if (author.role === "ROLE_ADMIN") {
        return true;
      }
      return this.maseProblem.author.id === author.id;
    },
    dateToString(date) {
      const parsed = new Date(date);
      return parsed.toLocaleDateString();
    },
    isVideo(mediaType) {
      return mediaType && mediaType.includes("video");
    },
    isImage(mediaType) {
      return !mediaType || mediaType.includes("image");
    },
    rateMase(arate, amaseId) {
      this.loading = true;
      rateMaseProblem(amaseId, arate).then((response) => {
        this.loading = false;
        if (!response.error) {
          this.$store.commit({
            type: "storeRating",
            maseId: amaseId,
            rate: arate,
          });
          if (response.data) {
            this.maseProblem.totalRates = response.data.totalRates;
            this.maseProblem.avgRate = response.data.avgRate;
          }
        }
      });
    },
  },
  mounted() {
    /*
    if (this.$refs.video_player) {
      setTimeout(() => {
        this.$refs.video_player.play();
      }, 2000);
    }
    */
  },
};
</script>
