<template>
  <div>
    <v-snackbar v-model="showerror" color="red">
      {{ messagetext }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showerror = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showmessage" color="green">
      {{ messagetext }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showmessage = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showmessage: false,
      showerror: false,
      messagetext: "Default text",
    };
  },
  methods: {
    doShowerror(errormessage) {
      this.messagetext = errormessage;
      this.showerror = true;
    },
    doShowmessage(message) {
      this.messagetext = message;
      this.showmessage = true;
    },
  },
  mounted() {
    this.$root.$on("message", (message) => {
      this.doShowmessage(message);
    });
    this.$root.$on("error", (message) => {
      this.doShowerror(message);
    });
  },
};
</script>
