<template>
  <v-container style="position: relative" fill-height fluid>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
          <div class="py-2">loading</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return this.$store.state.loading;
    },
  },
};
</script>
