<template>
  <v-app
    id="app"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <TheHead />

    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        :key="$route.fullPath"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      :key="$route.fullPath"
    ></router-view>

    <TheFoot />
    <Message ref="mref" />
  </v-app>
</template>

<script>
import TheHead from "./components/TheHead.vue";
import TheFoot from "./components/TheFoot.vue";
import Message from "./components/Message.vue";

export default {
  name: "App",
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      title: this.$t("HOMEPAGE_TITLE"),
      meta: [
        {
          name: "description",
          content: this.$t("HOMEPAGE_DESCRIPTION"),
        },
        {
          property: "og:title",
          content: this.$t("HOMEPAGE_TITLE"),
        },
        { property: "og:site_name", content: "Mase.rip" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  components: { TheHead, TheFoot, Message },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  data() {
    return {
      drawer: null,
      loading: false,
    };
  },
  mounted() {},
};
</script>
