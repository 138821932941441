<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <v-card v-if="!loading" class="mx-auto my-3" outlined elevation="2">
            <v-card-title class="text-h4"> {{ pageTitle }} </v-card-title>

            <v-card-text class="mx-1" :inner-html.prop="pageContent">
            </v-card-text>
          </v-card>
          <LoadingIndicator v-if="loading" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { getStaticPage } from "@/util/APIUtils";
import { showError } from "@/util/MASEUtils";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default {
  components: { LoadingIndicator },
  data() {
    return {
      pageId: this.$route.params.id,
      pageContent: "",
      pageTitle: "",
      loading: true,
    };
  },
  methods: {},
  mounted() {
    getStaticPage(this.pageId).then((response) => {
      if (!response.error) {
        this.pageContent = response.data[0].content.rendered;
        this.pageTitle = response.data[0].title.rendered;
      } else {
        showError(this, "failed to load static page");
      }
      this.loading = false;
    });
  },
};
</script>
<style lang="css" scoped>
.container {
}
</style>
