var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"mt-1":"","mb-1":""}},[_c('v-row',{},_vm._l((_vm.mases),function(mase,index){return _c('v-col',{key:mase.id,staticClass:"pt-2 pb-1 px-2 mb-1",attrs:{"offset":"0","cols":"12","offset-xs":"1","xs":"10","offset-sm":"1","sm":"10","offset-md":"0","md":"12","offset-lg":"0","lg":"6","offset-xl":"0","xl":"4"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
          name: 'maseproblem',
          params: {
            id: mase.id,
            title: _vm.generateUrlTitle(mase),
            locale: _vm.$i18n.locale,
          },
        }}},[_c('maseProblemCart',{attrs:{"maseProblem":mase,"mode":index % 2 == 0 ? 'vote' : 'rate',"activeTagId":_vm.activeTagId}})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }