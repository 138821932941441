<template>
  <div>
    <v-app-bar
      app
      height="53x"
      color="white"
      flat
      elevation="1"
      dense
      hide-on-scroll
    >
      <template v-slot:extension v-if="isHeadExtended">
        <v-container class="py-0 px-2 fill-height d-flex justify-center">
          <v-btn to="/" text>
            {{ $t("NAVIGATION_MAIN_ITEM") }}
          </v-btn>
          <div class="d-none d-sm-flex">
            <v-btn to="/addmase" text>
              {{ $t("NAVIGATION_ADD_MASE") }}
            </v-btn>
          </div>
        </v-container>
      </template>
      <v-container class="py-0 px-2 fill-height">
        <v-app-bar-nav-icon
          style="height: 50px; width: 50px"
          class="hidden-md-and-up"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title class="ma-0 pa-0 pr-4"
          ><a href="/">
            <v-img
              v-if="!$vuetify.theme.dark"
              :src="require('../assets/mase_large.png')"
              width="45px"
              height="45px"
            ></v-img>
            <v-img
              v-if="$vuetify.theme.dark"
              :src="require('../assets/mase_large.png')"
              height="45px"
              width="45px"
            ></v-img>
          </a>
        </v-toolbar-title>

        <!-- -->
        <v-btn
          v-if="!isHeadExtended"
          to="/"
          text
          :class="{ 'd-none d-md-flex': !searchClosed || search }"
        >
          {{ $t("NAVIGATION_MAIN_ITEM") }}
        </v-btn>

        <div v-if="!isHeadExtended">
          <v-btn to="/addmase" text class="d-none d-md-flex ml-2">
            {{ $t("NAVIGATION_ADD_MASE") }}
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-toolbar-items class="align-center">
          <v-responsive max-width="260">
            <v-form @submit.prevent="onSearch">
              <v-text-field
                ref="search"
                dense
                @click:prepend-inner="onSearch()"
                @click="onSearchClick"
                flat
                hide-details
                clearable
                rounded
                solo
                v-model="search"
                @focus="searchClosed = false"
                @blur="onBlur"
                class="
                  expanding-search
                  show
                  text-align-center
                  shrink
                  ma-0
                  pa-0
                  pr-2
                "
                :class="{ closed: searchClosed && !search }"
                filled
                :placeholder="this.$t('SEARCH_LABEL')"
                prepend-inner-icon="fa-search"
                background-color="grey lighten-2"
                color="grey-dark"
              ></v-text-field>
            </v-form>
          </v-responsive>
        </v-toolbar-items>
        <LoginModal ref="loginButton" v-if="!isLoggedIn" />
        <v-btn v-if="isLoggedIn" icon x-large href="/profile" text>
          <v-avatar size="38">
            <v-img
              :src="loggedInUser.avatarUrl"
              alt="loggedInUser.nickname"
              v-if="loggedInUser.avatarUrl"
            />
            <v-icon v-if="!loggedInUser.avatarUrl" x-large>
              fas fa-user-circle
            </v-icon>
          </v-avatar>
        </v-btn>
        <LocaleSwitcher
          :availableLanguages="$uiLocalesList"
          :selectedLanguageId="$i18n.locale"
          @onLanguageChange="changeLanguage"
        />
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list-item to="/" link @click="drawer = false">
        <v-list-item-icon>
          <v-icon>fa-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("NAVIGATION_MAIN_ITEM")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item to="/addmase" link>
          <v-list-item-icon>
            <v-icon>fa-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("NAVIGATION_ADD_MASE")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import LoginModal from "@/components/modal/LoginModal.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { setStoredLocale } from "@/util/STATEUtils";

export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.isUserLoggedIn;
    },
    loggedInUser() {
      return this.$store.state.userLoggedIn;
    },
    isHeadExtended() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },
  data() {
    return {
      query: this.$route.params.query,
      drawer: false,
      searchClosed: true,
      search: null,
      searchComponent: [],
      // { title: "MASE of the month", link: "/motm", icon: "trophy" },
      // { title: "Make money", link: "/affiliate", icon: "euro-sign" },
    };
  },
  methods: {
    changeLanguage(id) {
      this.$i18n.locale = id;
      setStoredLocale(id);
      const to = this.$router.resolve({ params: { locale: id } });
      this.$router.push(to.location).catch(() => {});
    },
    onLogin() {
      // this.$store.commit("setLogin", true);
      this.$store.commit("loadLogin");
    },
    onAddMase() {
      return false;
      // this.$router.push({ name: "addmase" });
    },
    onInitSearch(e) {
      console.log("onInitSearch", e);
      this.searchComponent = e;
    },
    onBlur() {
      if (!this.search) {
        this.searchClosed = true;
      }
    },
    onSearch() {
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
      if (this.searchClosed) {
        this.searchClosed = false;
      } else {
        console.log("onSearch", this.search, this.query);
        if (this.search) {
          if (this.query !== this.search) {
            this.$router
              .push({
                name: "search",
                params: { query: this.search },
              })
              .catch(() => {});
            this.query = this.search;
          }
        } else {
          this.$router
            .push({
              name: "home",
            })
            .catch(() => {});
        }
        this.$nextTick(() => {
          this.$refs.search.blur();
          this.searchClosed = false;
        });
      }
    },
    onSearchClick(event) {
      if (this.search) {
        event.target.setSelectionRange(0, this.search.length);
      }
    },
  },
  components: { LoginModal, LocaleSwitcher },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      if (to.name === "addmase" && !this.$store.state.isUserLoggedIn) {
        this.$refs.loginButton.open(window.location.origin + "/addmase");
      } else {
        next();
      }
    });
    if (this.$route.params.query) {
      this.search = this.$route.params.query;
      this.searchClosed = false;
    }
  },
};
</script>

<style lang="css">
.expanding-search .v-input__control .v-input__slot {
  cursor: pointer !important;
}

.expanding-search.show {
  transition: width 0.1s;
  width: 205px;
}

.expanding-search.closed {
  transition: width 0s;
  width: 80px;
}

.v-input__prepend-inner {
  padding-right: 10px !important;
}

.v-toolbar__content {
  padding: 0px 0px !important;
}

.search-eater {
  background-color: red;
}
</style>
