import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getStoredLocale } from "@/util/STATEUtils";

Vue.use(VueI18n);

const browserLocaleToLanguageMap = new Map();
browserLocaleToLanguageMap.set("de", "de");
browserLocaleToLanguageMap.set("en", "en");
browserLocaleToLanguageMap.set("es", "es");

const flagsMap = new Map();
flagsMap.set("de", "/flags/de.png");
flagsMap.set("en", "/flags/en.png");
flagsMap.set("es", "/flags/es.png");

Vue.prototype.$flagsMap = flagsMap;

const uiLocalesList = [{ id: "de", localization: "LANGUAGE_TITLE_DE" }, { id: "en", localization: "LANGUAGE_TITLE_EN" }, { id: "es", localization: "LANGUAGE_TITLE_ES" }];
Vue.prototype.$uiLocalesList = uiLocalesList;

const apiLocalesList = [{ id: "de", localization: "LANGUAGE_TITLE_DE" }, { id: "en", localization: "LANGUAGE_TITLE_EN" }, { id: "es", localization: "LANGUAGE_TITLE_ES" }];
Vue.prototype.$apiLocalesList = apiLocalesList;
Vue.prototype.$apiLocalesDefault = apiLocalesList[0];

const uiLocalesMap = new Map();
uiLocalesList.forEach((element) => {
  uiLocalesMap.set(element.id, element);
});
Vue.prototype.$uiLocalesMap = uiLocalesMap;

const apiLocalesMap = new Map();
apiLocalesList.forEach((element) => {
  apiLocalesMap.set(element.id, element);
});
Vue.prototype.$apiLocalesMap = apiLocalesMap;

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function loadLocale() {
  const storedLocale = getStoredLocale();
  if (storedLocale && uiLocalesMap.has(storedLocale)) {
    return storedLocale;
  }
  const loadedLocale = browserLocaleToLanguageMap.get(navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0]) || 'en';
  console.info("loadedLocale:", loadedLocale);
  return loadedLocale;
}

export default new VueI18n({

  locale: loadLocale(),
  // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});
