<template>
  <v-main>
    <v-container class="container" fluid>
      <maseProblemCart
        v-if="!loading && mase"
        :maseProblem="mase"
        usage="fullscreen"
        mode="vote"
        :autoplay="true"
      />
      <v-card
        elevation="1"
        outlined
        class="mt-5 pb-1 rounded-0"
        v-if="!loading && mase && answers > 0"
      >
        <v-card-title class="">
          <v-icon color="" large left>fas fa-comment-dots </v-icon>
          <span class="title"
            >{{ $tc("MASE_PROBLEM_SOLUTIONS", answers) }} ({{ answers }})
          </span>
        </v-card-title>
        <v-divider class="mx-4 pb-2"></v-divider>
        <v-card-text class="text-h6 ma-0 px-4" v-if="answers > 0">
          <MaseSolutionLine
            :last="index == answers - 1"
            :maseSolutionParam="maseSolution"
            :maseId="maseId"
            v-for="(maseSolution, index) in mase.solutions"
            :key="maseSolution ? maseSolution.id : 0"
          />
        </v-card-text>
      </v-card>

      <MaseSolutionForm
        v-if="!loading && mase"
        ref="maseSolutionForm"
        :maseId="maseId"
        :masePostDone="maseProblemPostDone"
      ></MaseSolutionForm>
    </v-container>

    <LoadingIndicator v-if="loading" />
  </v-main>
</template>

<script>
import maseProblemCart from "@/components/MaseProblemCart.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MaseSolutionLine from "@/components/MaseSolutionLine.vue";
import MaseSolutionForm from "@/components/form/MaseSolutionForm.vue";
import { getMase } from "@/util/APIUtils";
import { showError, fromSeoUrl } from "@/util/MASEUtils";

export default {
  metaInfo() {
    return {
      title: this.getPageTitle(),
      meta: [
        {
          name: "description",
          content: this.getPageDescription(),
        },
      ],
    };
  },
  components: {
    maseProblemCart,
    LoadingIndicator,
    MaseSolutionLine,
    MaseSolutionForm,
  },
  data() {
    return {
      loading: true,
      maseId: this.$route.params.id,
      mase: null,
      answers: 0,
    };
  },
  computed: {},
  mounted() {
    getMase(this.maseId).then((response) => {
      if (!response.error) {
        this.mase = response.data;
        if (this.mase.solutions && this.mase.solutions[0] != null) {
          this.answers = this.mase.solutions.length;
        }
      } else {
        showError(this, "failed to load mase");
      }
      this.loading = false;
      this.$meta().refresh();
    });
  },
  methods: {
    getPageTitle() {
      if (!this.mase) {
        return fromSeoUrl(this.$route.params.title);
      }
      return this.mase.title;
    },
    getPageDescription() {
      if (this.mase) return this.mase.description;
      return null;
    },
    maseProblemPostDone(solution) {
      console.info("maseProblemPostDone:", solution);
      if (solution) {
        if (!this.mase.solutions) {
          this.mase.solutions = [solution];
        } else {
          this.mase.solutions.push(solution);
        }
        this.answers += 1;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  max-width: 800px;
}
.preview {
  max-width: 600px;
}
/deep/ .tiptap-vuetify-editor__content {
  min-height: 250px;
}

/deep/ .ProseMirror {
  min-height: 200px;
}
</style>
