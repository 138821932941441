<template>
  <v-main>
    <v-container>
      <v-layout column class="mt-4">
        <v-card>
          <v-overlay opacity="0.5" absolute v-if="loading">
            <LoadingIndicator />
          </v-overlay>
          <v-card-text>
            <v-flex class="mb-8">
              <v-avatar size="150">
                <img :src="maseuser.avatarUrl" alt="" />
              </v-avatar>
            </v-flex>
            <v-text-field
              disabled
              v-model="maseuser.fullName"
              :label="this.$t('PROFILE_FULL_NAME')"
            ></v-text-field>

            <v-text-field
              disabled
              v-model="maseuser.email"
              :label="this.$t('PROFILE_EMAIL')"
            ></v-text-field>
            <v-text-field
              type="name"
              v-model="maseuser.nickname"
              :label="this.$t('PROFILE_DISPLAY_NAME')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              class="mb-2 ml-1"
              :loading="loading"
              @click.stop="updateAccount"
            >
              <v-icon left dark>fa-check</v-icon>
              {{ $t("SAVE_CHANGES") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-btn @click="onLogout" class="mt-5 error" large>
          {{ $t("PROFILE_LOGOUT") }}
        </v-btn>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { loadUser, updateUser } from "@/util/APIUtils";
import { showError, showMessage } from "@/util/MASEUtils";
import LoadingIndicator from "../components/LoadingIndicator.vue";

export default {
  components: { LoadingIndicator },
  methods: {
    onLogout() {
      this.$store.commit("logout");
      this.$router.push("/");
    },
    updateAccount() {
      this.loading = true;
      updateUser(this.maseuser).then((response) => {
        if (!response.error) {
          this.maseuser = response.data;
          showMessage(this, this.$t("PROFILE_UPDATE_CONFIRMATION_MESSAGE"));
        } else {
          showError(this, this.$t("PROFILE_UPDATE_ERROR_MESSAGE"));
        }
        this.loading = false;
      });
    },
  },
  data() {
    return {
      loading: true,
      maseuser: {},
      showAvatarPicker: false,
    };
  },
  async mounted() {
    const response = await loadUser();
    if (!response.error) {
      this.loading = false;
      this.maseuser = response.data;
    } else {
      showError(this, this.$t("PROFILE_LOAD_ERROR_MESSAGE"));
    }
  },
};
</script>
<style lang="css" scoped>
.container {
  max-width: 800px;
}
</style>
