import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import AddMase from '@/views/AddMase.vue';
import EditMase from '@/views/EditMaseProblem.vue';
import MaseProblem from '@/views/MaseProblem.vue';
import StaticPage from '@/views/Static.vue';
import Login from '@/views/Login.vue';
import Tag from '@/views/Tag.vue';
import Profile from '@/views/Profile.vue';
import Search from '@/views/Search.vue';
import { setStoredLocale } from "@/util/STATEUtils";

import i18n from '../../i18n';

// import { hasAccessToken } from "@/util/STATEUtils";

Vue.use(VueRouter);
function handleLocale(to, from, next, basePath) {
    const locale = to.params.locale;
    console.info("loaded locale:", locale);
    if (!locale || !Vue.prototype.$uiLocalesMap.has(locale)) {
        return next(basePath + '/' + i18n.locale);
    }
    if (i18n.locale !== locale) {
        i18n.locale = locale;
        setStoredLocale(locale);
    }
    return next();
}
const routes = [
    {
        path: '/addmase',
        name: 'addmase',
        component: AddMase,
        meta: { keepAlive: false },
    },
    {
        path: '/:locale?',
        name: 'home',
        component: Home,
        meta: { keepAlive: true },
        beforeEnter: (to, from, next) => handleLocale(to, from, next, '')
    },
    {
        path: '/editmase/:id',
        name: 'editmase',
        component: EditMase,
        meta: { keepAlive: false },
    },
    {
        path: '/mp/:title/:id/:locale?',
        name: 'maseproblem',
        component: MaseProblem,
        meta: { keepAlive: true },
        beforeEnter: (to, from, next) => handleLocale(to, from, next, '/mp/' + to.params.title + "/" + to.params.id)
    },
    {
        path: '/search/:query',
        name: 'search',
        component: Search,
        meta: { keepAlive: true },
    },
    {
        path: '/tag/:id',
        name: 'tag',
        component: Tag,
        meta: { keepAlive: true },
    },
    {
        path: '/static/:id',
        name: 'static',
        component: StaticPage,
        meta: { keepAlive: true },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { keepAlive: false },
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: { keepAlive: false },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
