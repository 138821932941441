<template>
  <v-main class="ma-0 pa-0">
    <v-card
      elevation="1"
      outlined
      class="mt-5 pb-6 cart rounded-0"
      v-if="maseSolution"
    >
      <v-card-title class="">
        <v-icon color="" large left>fa-lightbulb</v-icon>
        <span class="title mt-1" v-if="formMode === 'NEW'"
          >{{ $t("SOLUTION_FORM_ADD_LABEL") }}
        </span>

        <span class="title mt-1" v-if="formMode === 'EDIT'"
          >{{ $t("SOLUTION_FORM_UPDATE_LABEL") }}
        </span>
      </v-card-title>
      <!--<v-divider class="mx-4 pb-2"></v-divider>-->
      <v-divider class="mx-0 pb-0"></v-divider>
      <v-card-text class="text-h6 ma-0 pa-0 px-0">
        <v-sheet color="brown lighten-5 mx-0 px-4 pt-2 pb-1" elevation="1">
          <v-icon color="" left>fa-tag</v-icon>

          <span class="title mt-2">{{
            $t("SOLUTION_FORM_PRODUCT_TITLE")
          }}</span>
          <v-row class="mx-0 mb-0 mt-3">
            <v-text-field
              background-color="white"
              @click="checkLogin()"
              v-bind="solutionLink"
              height="40px"
              class="ma-0 pa-0 mr-0"
              hide-details="auto"
              dense
              outlined
              v-model="productTextField"
              v-bind:loading="loadingPdp"
            ></v-text-field>
          </v-row>

          <v-row class="mx-0 mb-3 mt-0">
            <ProductLine
              v-bind:product="maseSolution.product"
              v-bind:loading="loadingPdp"
            />
            <v-spacer />
          </v-row>
        </v-sheet>
        <v-divider class="mx-0 pb-0"></v-divider>
        <v-sheet color="brown lighten-5 mx-0 px-4 pt-2 pb-1 mt-0" elevation="1">
          <v-icon color="" left>fa-edit</v-icon>
          <span class="title mt-2">{{ $t("SOLUTION_FORM_DETAIL_TITLE") }}</span>

          <MultiLangText
            @click="checkLogin()"
            placeholder="SOLUTION_FORM_ADD_DESCRIPTION"
            v-model="maseSolution.translations"
            fieldName="description"
            ref="textMultiLang"
          />
          <v-row class="mx-0 mb-3 mt-0">
            <VueUploadImages
              v-if="maseSolution"
              ref="imageUploader"
              :max="2"
              class="mt-1"
              :initialMedia="maseSolution.medias ? maseSolution.medias : null"
            />
          </v-row>
        </v-sheet>
      </v-card-text>
      <v-card-actions class="ma-0 pa-0 px-4 pt-4">
        <v-btn
          :loading="posting"
          :disabled="deleting"
          @click.stop="submitSolution"
          class="success ma-0"
          large
          v-if="formMode === 'NEW'"
          >{{ $t("SOLUTION_FORM_BUTTON_ADD") }}</v-btn
        >

        <v-btn
          :loading="posting"
          :disabled="deleting"
          @click.stop="editSolution"
          class="success ma-0"
          large
          v-if="formMode === 'EDIT'"
          >{{ $t("SAVE_CHANGES") }}</v-btn
        >

        <v-btn
          :loading="deleting"
          :disabled="posting"
          @click.stop="deleteSolution"
          class="error ma-0 ml-5"
          large
          v-if="formMode === 'EDIT'"
          >{{ $t("SOLUTION_FORMDELETE_TITLE") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <Confirm ref="confirm" />
  </v-main>
</template>

<script>
import MultiLangText from "@/components/MultiLangText.vue";
import {
  CREATE_MASE_SOLUTION_URL,
  UPDATE_MASE_SOLUTION_URL,
  UPLOAD_MASE_SOLUTION_MEDIA_URL,
  CANCELED,
} from "@/constants";
import {
  editMase,
  createMase,
  showError,
  showMessage,
  validURL,
} from "@/util/MASEUtils";
import { deleteMaseSolution, getPDP, cancelRequest } from "@/util/APIUtils";
import Confirm from "@/components/Confirm.vue";

import VueUploadImages from "@/components/upload/Uploader.vue";
import ProductLine from "@/components/ProductLine.vue";

export default {
  components: {
    VueUploadImages,
    ProductLine,
    Confirm,
    MultiLangText,
  },
  props: {
    initialMaseSolution: {
      type: Object,
      required: false,
    },
    maseId: {
      type: String,
      required: true,
    },
    masePostDone: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      editor: [],
      formMode: "NEW",
      posting: false,
      deleting: false,
      maseSolution: {
        product: {
          hide: true,
        },
        translations: [],
        maseProblemId: this.maseId,
      },
      solutionLink: {
        error: false,
        value: "",
        placeholder: this.$t("SOLUTION_FORM_ADD_LINK"),
        hint: this.$t("SOLUTION_FORM_HINT_LINK"),
      },
      productTextField: "",
      loadingPdp: false,
      testTexts: [
        {
          language: "de_DE",
          title: "DE title",
          description: "DE description",
        },
        {
          language: "en_EN",
          title: "EN title",
          description: "EN description",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    if (this.initialMaseSolution) {
      this.formMode = "EDIT";
      this.maseSolution = JSON.parse(JSON.stringify(this.initialMaseSolution));
      if (this.initialMaseSolution.medias) {
        this.$refs.imageUploader.loadInitialMedia(
          this.initialMaseSolution.medias
        );
      }
      if (this.initialMaseSolution.product) {
        this.productTextField =
          this.initialMaseSolution.product.originalProductLink;
      }
    }
    /*
    else {
      this.maseSolution = { translations: [] };
      this.maseSolution.maseProblemId = this.maseId;
    }
    */
    this.$nextTick(() => this.$refs.textMultiLang.initLanguage());
  },
  watch: {
    productTextField(newVal) {
      cancelRequest();
      if (
        !this.maseSolution.product ||
        newVal !== this.maseSolution.product.originalProductLink
      ) {
        if (validURL(newVal)) {
          this.maseSolution.product = {};
          this.maseSolution.product.originalProductLink = newVal;
          this.loadProductData(this.maseSolution.product.originalProductLink);
          // this.solutionLink.disabled = true;
        } else {
          this.loadingPdp = false;
          this.maseSolution.product = {};
        }
      }
    },
  },
  methods: {
    toggleTranslations() {
      console.info("toggle translations");
    },
    onInitEditor(e) {
      this.editor = e.editor;
    },
    checkLogin() {
      if (!this.$store.state.isUserLoggedIn) {
        this.$root.$emit("openLogin");
        return false;
      }
      return true;
    },
    loadProductData(productUrl) {
      this.maseSolution.product = {};
      this.loadingPdp = true;
      const cleanedUrl = productUrl.split("?")[0];
      const encoded = Buffer.from(cleanedUrl).toString("base64");
      getPDP(encoded).then((response) => {
        this.loadingPdp = false;
        if (response.data) {
          this.maseSolution.product = response.data;
        } else if (response.status !== CANCELED) {
          this.maseSolution.product = { hide: true };
          showError(this, this.$t("ERROR_LOADING_PDP"));
        }
        this.maseSolution.product.originalProductLink = productUrl;
      });
    },
    validateFields() {
      if (!this.checkLogin()) {
        return false;
      }

      this.solutionLink.error = false;
      this.$refs.textMultiLang.outlined = false;
      let error = this.$t("FORM_NO_EMPTY_FIELDS");
      if (this.$refs.textMultiLang.isEmpty()) {
        this.$refs.textMultiLang.outlined = true;
      }
      if (
        this.maseSolution.product &&
        this.maseSolution.product.originalProductLink &&
        !validURL(this.maseSolution.product.originalProductLink)
      ) {
        this.solutionLink.error = true;
        error = this.$t("SOLUTION_INVALID_LINK");
      } else if (!this.maseSolution.product) {
        this.solutionLink.error = false;
      }
      if (this.solutionLink.error || this.$refs.textMultiLang.outlined) {
        showError(this, error);
        return false;
      }
      return true;
    },
    submitSolution() {
      if (this.validateFields()) {
        this.posting = true;
        createMase(
          CREATE_MASE_SOLUTION_URL,
          UPLOAD_MASE_SOLUTION_MEDIA_URL,
          this.maseSolution,
          this.$refs.imageUploader
        )
          .then((newCreatedSolution) => {
            this.reportPostedSolution(newCreatedSolution);
            this.posting = false;
            this.maseSolution.description = "";
            if (this.maseSolution.product) {
              this.maseSolution.product.originalProductLink = "";
            }
            this.productTextField = "";
            this.maseSolution.product = { hide: true };
            showMessage(
              this,
              this.$t("SOLUTIUON_FORM_ADDED_CONFIRMATION_MESSAGE")
            );
          })
          .catch((e) => {
            console.error(e);
            showError(this, this.$t("SOLUTION_FORM_ADD_ERROR_MESSAGE"));
            this.posting = false;
          });
      }
    },
    editSolution() {
      console.info("edit solution", this.maseSolution);
      if (this.validateFields()) {
        this.posting = true;
        editMase(
          UPDATE_MASE_SOLUTION_URL,
          UPLOAD_MASE_SOLUTION_MEDIA_URL,
          this.maseSolution,
          this.$refs.imageUploader
        )
          .then((updatedMase) => {
            this.reportPostedSolution(updatedMase);
            this.posting = false;
            showMessage(
              this,
              this.$t("SOLUTION_FORM_UPDATED_CONFIRMATION_MESSAGE")
            );
          })
          .catch((e) => {
            showError(this, this.$t("SOLUTION_FORM_UPDATED_ERROR_MESSAGE"));
            console.warn("failed to update mase solution", e);
            this.posting = false;
          });
      }
    },
    deleteSolution() {
      this.$refs.confirm
        .open(
          this.$t("SOLUTION_FORMDELETE_TITLE"),
          this.$t("SOLUTION_FORMDELETE_MESSAGE"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            deleteMaseSolution(this.maseSolution.id).then((response) => {
              if (!response.error) {
                showMessage(
                  this,
                  this.$t("SOLUTION_FORM_DELETED_CONFIRMATION_MESSAGE")
                );
                this.loading = false;
                this.reportPostedSolution(null);
              } else {
                showError(this, this.$t("SOLUTION_FORM_DELETED_ERROR_MESSAGE"));
              }
            });
          }
        });
    },
    reportPostedSolution(solution) {
      if (this.masePostDone) {
        this.masePostDone(solution);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  max-width: 800px;
}
.preview {
  max-width: 600px;
}
/deep/ .tiptap-vuetify-editor__content {
  min-height: 250px;
}

/deep/ .ProseMirror {
  min-height: 200px;
}

.s-recommendations__headline--right {
  margin-right: 0;
  margin-left: 0.5rem;
}

.s-recommendations__headline {
  border-bottom: 2px solid;
  flex-grow: 1;
  position: relative;
  bottom: 0.375rem;
  margin-right: 0.5rem;
  border-color: inherit;
}
</style>
