<template>
  <v-container mt-1 mb-1>
    <v-row class="">
      <v-col
        class="pt-2 pb-1 px-2 mb-1"
        offset="0"
        cols="12"
        offset-xs="1"
        xs="10"
        offset-sm="1"
        sm="10"
        offset-md="0"
        md="12"
        offset-lg="0"
        lg="6"
        offset-xl="0"
        xl="4"
        v-for="(mase, index) in mases"
        :key="mase.id"
      >
        <router-link
          :to="{
            name: 'maseproblem',
            params: {
              id: mase.id,
              title: generateUrlTitle(mase),
              locale: $i18n.locale,
            },
          }"
          class="text-decoration-none"
        >
          <maseProblemCart
            :maseProblem="mase"
            :mode="index % 2 == 0 ? 'vote' : 'rate'"
            :activeTagId="activeTagId"
          />
        </router-link>
      </v-col>
    </v-row>
    <!-- If using vue-router -->
  </v-container>
</template>

<script>
import maseProblemCart from "./MaseProblemCart.vue";
import { toSeoUrl } from "@/util/MASEUtils";

export default {
  components: {
    maseProblemCart,
  },
  data() {
    return {};
  },
  methods: {
    generateUrlTitle(mase) {
      return encodeURIComponent(toSeoUrl(mase.title.toLowerCase()));
    },
  },
  props: {
    mases: {
      type: Array,
      required: true,
    },
    activeTagId: {
      type: String,
      required: false,
    },
  },
  mounted() {},
};
</script>

<style lang="css" scoped>
.col {
  max-width: 600px;
}
</style>
