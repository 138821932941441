<script>
import Media from "@dongido/vue-viaudio";
import axios from "axios";
import { MUILTIPART_CHUNK_SIZE } from "../../constants";
import { getAccessToken } from "../../util/STATEUtils";
import FileUpload from "../../util/chunked_upload/FileUpload";
import { getDefaultMediaUrlFromMedia } from "@/util/MASEUtils";

export default {
  components: {
    Media,
  },
  name: "VueUploadImages", // vue component name
  computed: {},
  data() {
    return {
      error: "",
      files: [],
      dropped: 0,
      Imgs: [],
      initialMedia: [],
    };
  },
  props: {
    max: Number,
    uploadMsg: String,
    maxError: String,
    fileError: String,
    clearAll: String,
  },
  mounted() {
    /*
    if (this.initialMedia) {
      this.initialMedia.forEach((media) => {
        this.Imgs.push(getDefaultMediaUrlFromMedia(media));
        const fileUpload = new FileUpload();
        fileUpload.fromMaseMedia(media);
        this.files.push(fileUpload);
      });
    }
    console.info(
      "mounted VueUploadImages",
      this.Imgs,
      this.initialMedia,
      this.files
    );
    */
  },
  methods: {
    loadInitialMedia(mountedMedia) {
      if (mountedMedia) {
        console.info("loadInitialMedia", mountedMedia);
        this.initialMedia = [...mountedMedia];
        if (this.initialMedia) {
          this.initialMedia.forEach((media) => {
            this.Imgs.push(getDefaultMediaUrlFromMedia(media));
            const fileUpload = new FileUpload();
            fileUpload.fromMaseMedia(media);
            this.files.push(fileUpload);
          });
          console.info(
            "VueUploadImages loadInitialMedia",
            this.initialMedia,
            this.Imgs,
            this.files
          );
        }
      }
    },
    getDefaultMediaUrlFromMedia,
    progressStatus(index) {
      return this.files[index].progressStatus;
    },
    removeMediaById(mediaId) {
      let i = 0;
      if (this.initialMedia) {
        for (i = 0; i < this.initialMedia.length; i += 1) {
          if (this.initialMedia[i].id === mediaId) {
            this.initialMedia.splice(i, 1);
            return;
          }
        }
      }
    },
    removeAllMedias() {
      if (this.initialMedia) {
        while (this.initialMedia.length > 0) {
          this.initialMedia.pop();
        }
      }
    },
    deleteImg(index) {
      const file = this.files[index];
      if (file.fromServer) {
        this.removeMediaById(file.id);
      }
      this.Imgs.splice(index, 1);
      this.files.splice(index, 1);
      this.$emit("change", this.files);
      this.$refs.uploadInput.value = null;
    },
    dragOver() {
      this.dropped = 2;
    },
    dragLeave() {},
    drop(e) {
      let status = true;
      const files = Array.from(e.dataTransfer.files);
      if (e && files) {
        files.forEach((file) => {
          if (file.type.startsWith("image") === false) status = false;
        });
        if (status === true) {
          if (
            this.$props.max &&
            files.length + this.files.length > this.$props.max
          ) {
            this.error = this.$props.maxError
              ? this.$props.maxError
              : `Maximum files is` + this.$props.max;
          } else {
            files.forEach((file) => {
              const newFile = new FileUpload();
              newFile.fromFile(file);
              this.files.push(newFile);
            });
            this.previewImgs();
          }
        } else {
          this.error = this.$props.fileError
            ? this.$props.fileError
            : `Unsupported file type`;
        }
      }
      this.dropped = 0;
    },
    append() {
      this.$refs.uploadInput.click();
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = () => {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    readAsURL(file) {
      return new Promise((resolve) => {
        resolve(file.url);
      });
    },
    readAsVideoPreview(file) {
      return new Promise((resolve) => {
        if (file.url) {
          resolve(file.url);
        } else {
          resolve(URL.createObjectURL(file));
        }
      });
    },
    previewImgs(event) {
      if (
        this.$props.max &&
        event &&
        event.currentTarget.files.length + this.files.length > this.$props.max
      ) {
        this.error = this.$props.maxError
          ? this.$props.maxError
          : `Maximum files is` + this.$props.max;
        return;
      }
      if (this.dropped === 0 && event.currentTarget.files) {
        event.currentTarget.files.forEach((file) => {
          const newFile = new FileUpload();
          newFile.fromFile(file);
          this.files.push(newFile);
        });
      }
      this.error = "";
      this.$emit("change", this.files);
      const readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i += 1) {
        console.info("Iterating:", this.files[i]);
        if (
          this.files[i].file &&
          this.files[i].type &&
          this.files[i].type.includes("video")
        ) {
          console.info("VIDEO:", this.files[i]);
          readers.push(this.readAsVideoPreview(this.files[i].file));
        } else if (!this.files[i].fromServer) {
          readers.push(this.readAsDataURL(this.files[i].file));
        } else {
          readers.push(this.readAsURL(this.files[i]));
        }
      }
      Promise.all(readers).then((values) => {
        this.Imgs = values;
      });
    },
    reset() {
      this.$refs.uploadInput.value = null;
      this.removeAllMedias();
      this.Imgs = [];
      this.files = [];
      this.$emit("change", this.files);
    },
    isVideo(mediaType) {
      return mediaType && mediaType.includes("video");
    },
    isImage(mediaType) {
      return !mediaType || mediaType.includes("image");
    },
    handleMediaUpload(maseMediaEndpointURL, mase, resolve, reject) {
      const mediasToPost = [];
      this.files.forEach((file) => {
        if (file && !file.fromServer) {
          file.setProgressStatus("queued");
          mediasToPost.push(file);
          this.$forceUpdate();
        }
      });

      const lastTask = mediasToPost.reduce((previousPromise, nextMedia) => {
        return previousPromise
          .then(() => {
            return this.startMultipartUploadFile(
              maseMediaEndpointURL,
              nextMedia,
              mase
            );
          })
          .catch((err) => {
            console.warn("errormedia post", err.message);
          });
      }, Promise.resolve());

      lastTask.then((lastPost) => {
        if (!lastPost) {
          resolve(mase);
        } else if (!lastPost.error) {
          this.reset();
          resolve(lastPost.data);
        } else {
          reject(new Error(lastPost));
        }
      });
    },
    startMultipartUploadFile(maseMediaEndpointURL, fileObj, mase) {
      fileObj.setProgressStatus("uploading");
      return new Promise((resolve, reject) => {
        this.multipartUploadFile(
          maseMediaEndpointURL,
          fileObj,
          resolve,
          reject,
          mase
        );
      });
    },
    multipartUploadFile(maseMediaEndpointURL, fileObj, resolve, reject, mase) {
      const totalParts = Math.ceil(fileObj.file.size / MUILTIPART_CHUNK_SIZE);
      let i = 1;
      const queue = [];
      do {
        const currentPart = i;
        const blob = this.getFileChunk(fileObj, currentPart);
        const data = new FormData();
        const config = {
          onUploadProgress: (progressEvent) => {
            fileObj.setMultipartProgress(
              progressEvent,
              totalParts,
              currentPart
            );
          },
        };
        data.append("multipart", true);
        data.append("file", blob);
        data.append("filename", fileObj.file.name);
        data.append("mime", fileObj.file.type);
        data.append("totalSize", fileObj.file.size);
        data.append("partSize", MUILTIPART_CHUNK_SIZE);
        data.append("totalParts", totalParts);
        data.append("currentPart", currentPart);
        data.append("maseId", mase.id);
        queue.push({
          data,
          config,
          fileObj,
          currentPart,
        });
        i += 1;
      } while (i <= totalParts);

      this.processQueue(
        maseMediaEndpointURL,
        queue,
        fileObj,
        null,
        resolve,
        reject
      );
    },
    cleanQueue(queue, fileObj, response) {
      if (!response) {
        return queue;
      }
      if (response.data.meta && response.data.meta.remainingParts) {
        return queue.filter((item) => {
          const uploaded =
            response.data.meta.remainingParts.includes(item.currentPart) ===
            false;
          if (uploaded) {
            fileObj.uploadedParts.push({
              part: item.currentPart,
              loaded: 100,
            });
          }
          return !uploaded;
        });
      }
      return queue;
    },
    processQueue(
      maseMediaEndpointURL,
      pqueue,
      fileObj,
      presponse,
      resolve,
      reject
    ) {
      const queue = this.cleanQueue(pqueue, fileObj, presponse);
      if (!queue.length) {
        resolve(presponse);
        fileObj.setProgressStatus(null);
        return true;
      }
      const part = queue.shift();

      const config = {};
      let headers = {};
      const accessToken = getAccessToken();
      if (accessToken) {
        headers = { Authorization: "Bearer " + accessToken };
      }
      if (headers) {
        config.headers = headers;
      }
      const client = axios.create(config);

      return client
        .post(maseMediaEndpointURL, part.data, part.config)
        .then((response) => {
          this.processQueue(
            maseMediaEndpointURL,
            queue,
            fileObj,
            response,
            resolve,
            reject
          );
        })
        .catch((error) => {
          console.error(error);
          reject(error);
          /*
            if (error.request && error.request.status === 429) {
                queue.push(part);
                setTimeout(function () {
                    this.processQueue();
                }, 60000); // should be from retry-after header
            }
            */
          part.fileObj.error = error.response.data;
        });
    },
    getFileChunk(fileObj, part) {
      const start = (part - 1) * MUILTIPART_CHUNK_SIZE;
      const end = Math.min(start + MUILTIPART_CHUNK_SIZE, fileObj.file.size);
      return fileObj.file.slice(start, end);
    },
  },
};
</script>

<template>
  <div
    class="container"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop($event)"
  >
    <div class="drop" v-show="dropped == 2"></div>
    <!-- Error Message -->
    <div v-show="error" class="error">
      {{ error }}
    </div>

    <!-- To inform user how to upload image -->
    <div v-show="Imgs.length == 0" class="beforeUpload">
      <input
        type="file"
        style="z-index: 1"
        accept="image/*, video/*"
        ref="uploadInput"
        @change="previewImgs"
        multiple
      />
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Upload Image</title>
        <g id="Upload_Image" data-name="Upload Image">
          <g id="_Group_" data-name="&lt;Group&gt;">
            <g id="_Group_2" data-name="&lt;Group&gt;">
              <g id="_Group_3" data-name="&lt;Group&gt;">
                <circle
                  id="_Path_"
                  data-name="&lt;Path&gt;"
                  cx="18.5"
                  cy="16.5"
                  r="5"
                  style="
                    fill: none;
                    stroke: #303c42;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  "
                />
              </g>
              <polyline
                id="_Path_2"
                data-name="&lt;Path&gt;"
                points="16.5 15.5 18.5 13.5 20.5 15.5"
                style="
                  fill: none;
                  stroke: #303c42;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                "
              />
              <line
                id="_Path_3"
                data-name="&lt;Path&gt;"
                x1="18.5"
                y1="13.5"
                x2="18.5"
                y2="19.5"
                style="
                  fill: none;
                  stroke: #303c42;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                "
              />
            </g>
            <g id="_Group_4" data-name="&lt;Group&gt;">
              <polyline
                id="_Path_4"
                data-name="&lt;Path&gt;"
                points="0.6 15.42 6 10.02 8.98 13"
                style="
                  fill: none;
                  stroke: #303c42;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                "
              />
              <polyline
                id="_Path_5"
                data-name="&lt;Path&gt;"
                points="17.16 11.68 12.5 7.02 7.77 11.79"
                style="
                  fill: none;
                  stroke: #303c42;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                "
              />
              <circle
                id="_Path_6"
                data-name="&lt;Path&gt;"
                cx="8"
                cy="6.02"
                r="1.5"
                style="
                  fill: none;
                  stroke: #303c42;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                "
              />
              <path
                id="_Path_7"
                data-name="&lt;Path&gt;"
                d="M19.5,11.6V4A1.5,1.5,0,0,0,18,2.5H2A1.5,1.5,0,0,0,.5,4V15A1.5,1.5,0,0,0,2,16.5H13.5"
                style="
                  fill: none;
                  stroke: #303c42;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                "
              />
            </g>
          </g>
        </g>
      </svg>

      <p class="mainMessage">
        {{ uploadMsg ? uploadMsg : this.$t("UPLOAD_COMPONENT_HINT") }}
      </p>
    </div>

    <v-row v-if="Imgs.length > 0" style="height: 12px" class="">
      <v-spacer />
      <v-btn
        @click="reset"
        elevation="0"
        text
        outlined
        class="mr-2 mt-2 rounded-tr-lg"
        small
        width="55px"
        >clear <br />All
      </v-btn>
    </v-row>
    <v-row class="mt-0 pt-0 pr-14" v-if="Imgs.length > 0">
      <v-col
        v-for="(img, i) in Imgs"
        :key="i"
        class="d-flex child-flex pl-3 pr-0"
        cols="auto"
      >
        <v-sheet
          width="150px"
          height="150px"
          class="rounded-lg"
          :style="{ position: 'relative' }"
        >
          <v-overlay
            :value="true"
            class="rounded-lg"
            :absolute="true"
            opacity="0.6"
            v-if="progressStatus(i)"
          >
            {{ progressStatus(i) == "queued" ? "Waiting" : "Uploading" }}
            <v-progress-linear
              height="10px"
              color="red"
              v-model="files[i].multipartUploadPercent"
            ></v-progress-linear>
          </v-overlay>
          <Media
            class="rounded-lg mx-0 px-0"
            :ref="'video_player'"
            :kind="'video'"
            :controls="true"
            v-if="isVideo(files[i].type) && !progressStatus(i)"
            :src="[img]"
            :style="{
              width: '150px',
              height: '150px',
              'background-color': 'black',
            }"
            :autoplay="false"
            :loop="true"
          >
          </Media>
          <v-btn
            class="ma-1 ml-n9"
            style="float: right"
            elevation="0"
            fab
            x-small
            color="red"
            v-if="isVideo(files[i].type)"
            @click="deleteImg(i)"
          >
            <v-icon color="white" size="15"> fas fa-trash </v-icon>
          </v-btn>

          <v-img
            height="150px"
            width="150px"
            contain
            :src="img"
            aspect-ratio="1"
            class="brown lighten-5 rounded-lg"
            v-if="!isVideo(files[i].type)"
          >
            <v-btn
              class="ma-1"
              style="float: right"
              elevation="0"
              fab
              x-small
              color="red"
              @click="deleteImg(i)"
            >
              <v-icon color="white" size="15"> fas fa-trash </v-icon>
            </v-btn>
          </v-img>
        </v-sheet>
      </v-col>
      <v-col
        class="d-flex child-flex align-center ma-0 pa-0 pl-2"
        cols="auto"
        v-if="Imgs.length > 0 && Imgs.length < $props.max"
      >
        <v-btn
          class="plus mx-0"
          elevation="0"
          fab
          color="green"
          small
          @click="append"
        >
          <v-icon color="white" size="19"> fas fa-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  border: 0.5px solid #a3a8b1;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}
.drop {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  position: absolute;
  background-color: #f4f6ff;
  left: 0;
  border: 3px dashed #a3a8b1;
}
.error {
  text-align: center;
  color: red;
  font-size: 15px;
}
.beforeUpload {
  position: relative;
  text-align: center;
}
.beforeUpload input {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
}
.beforeUpload input:hover {
  cursor: pointer;
}
.beforeUpload .icon {
  width: 150px;
  margin: auto;
  display: block;
}
.imgsPreview .imageHolder {
  width: 150px;
  height: 150px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 10px 10px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imgsPreview .imageHolder .delete {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 29px;
  height: 29px;
  color: #fff;
  background: red;
  border-radius: 50%;
}
.imgsPreview .imageHolder .delete:hover {
  cursor: pointer;
}
.imgsPreview .imageHolder .delete .icon {
  width: 66%;
  height: 66%;
  display: block;
  margin: 4px auto;
}
.imgsPreview .imageHolder .plus {
  color: #2d3748;
  font-size: 21pt;
  height: 35px;
  width: 35px;
  text-align: center;
  border: 1px solid #2d3748;
  line-height: 23px;
  position: absolute;
  right: -48px;
  bottom: 39%;
}
.plus:hover {
  cursor: pointer;
}
.clearButton {
  color: #2d3748;
  position: absolute;
  top: 7px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
