import Vue from 'vue';

import {
    TiptapVuetifyPlugin, Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    BulletList,
    OrderedList,
    ListItem,
    Link
} from 'tiptap-vuetify';
import vuetify from '../vuetify';
import 'tiptap-vuetify/dist/main.css';

Vue.prototype.$editorSettings = [
    Bold,
    Underline,
    Strike,
    Italic,
    Link,
    ListItem,
    BulletList,
    OrderedList,
    [
        Heading,
        {
            options: {
                levels: [1, 2, 3, 4],
            },
        },
    ],

];

Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'fa',
});
