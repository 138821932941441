<template>
  <v-card class="mx-auto my-3 pb-5" outlined elevation="2">
    <v-card-text class="text-h6 ma-0 pa-0 px-0">
      <v-sheet color="brown lighten-5 mx-0 px-4 pt-2 pb-1" elevation="1">
        <v-icon color="" left>fa-edit</v-icon>

        <span class="title mt-2">{{ $t("PROBLEM_FORM_TITLE") }}</span>
        <MultiLangText
          placeholder="PROBLEM_FORM_ADD_TITLE"
          v-model="this.maseProblem.translations"
          fieldName="title"
          :multiline="false"
          :initialFocus="true"
          ref="textMultiLang"
        />
        <MultiLangText
          placeholder="SOLUTION_FORM_ADD_DESCRIPTION"
          v-model="this.maseProblem.translations"
          fieldName="description"
          ref="descriptionMultiLang"
        />

        <v-combobox
          ref="hastagCombobox"
          append-icon="fa-chevron-down"
          clearable
          v-model="maseProblem.tags"
          :items="alreadyExistingTags"
          :search-input.sync="search"
          hide-selected
          :hint="this.$t('PROBLEM_FORM_ADD_TAG_MAX')"
          :label="this.$t('PROBLEM_FORM_ADD_TAG_DESCRIPTION')"
          multiple
          small-chips
          deletable-chips
          persistent-hint
          solo
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true,
            maxHeight: 200,
          }"
          v-on:change="onComboChange"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <p
                    v-html="
                      $t('PROBLEM_FORM_ADD_TAG_NOTFOUND', { search: search })
                    "
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-sheet>

      <v-divider class="mx-0 pb-0"></v-divider>

      <!--
      <v-input
        outlined
        prepend-icon="fa-edit"
        persistent-hint
        class="text-h6 ma-0 pa-0 flex-grow-1 flex-shrink-1"
      >
        <v-sheet outlined flat rounded class="flex-grow-1 flex-shrink-1">
          <tiptap-vuetify
            @init="onInitEditor"
            :placeholder="this.$t('PROBLEM_FORM_ADD_DESCRIPTION')"
            class="mx-0 my-0 outlined"
            :card-props="{
              'min-height': '180',
              outlined: true,
              color: $vuetify.theme.dark ? '#121212' : 'white',
              elevation: 2,
            }"
            fill-width
            v-model="maseProblem.description"
            :extensions="this.$editorSettings"
            :toolbar-attributes="{
              color: $vuetify.theme.dark ? 'black' : 'grey lighten-4',
              dark: false,
            }"
          />
        </v-sheet>
      </v-input>-->

      <v-sheet color="brown lighten-5 mx-0 px-4 pt-2 pb-1 mt-0" elevation="1">
        <v-icon color="" left>fas fa-camera</v-icon>
        <span class="title mt-2">{{ $t("PROBLEM_FORM_ADD_MEDIA_TITLE") }}</span>
        <VueUploadImages
          ref="imageUploader"
          :max="2"
          :initialMedia="maseProblem ? maseProblem.medias : null"
        />
      </v-sheet>
    </v-card-text>
    <v-card-actions class="ma-0 pa-0 px-4 pt-4">
      <v-btn
        :loading="posting"
        :disabled="deleting"
        @click.stop="addMase"
        class="success ma-0"
        v-if="formMode === 'NEW'"
        >{{ $t("PROBLEM_FORM_BUTTON_ADD") }}</v-btn
      >
      <v-btn
        :loading="posting"
        :disabled="deleting"
        @click="editMaseProblem"
        class="success mt-2"
        v-if="formMode === 'EDIT'"
        >{{ $t("PROBLEM_FORM_BUTTON_EDIT") }}</v-btn
      >
      <v-btn
        :loading="deleting"
        :disabled="posting"
        @click="deleteMase"
        class="error mx-0 mt-2 ml-4"
        v-if="formMode === 'EDIT'"
        >{{ $t("PROBLEM_FORM_BUTTON_DELETE") }}</v-btn
      >
      <Confirm ref="confirm" />
    </v-card-actions>
  </v-card>
</template>

<script>
// import { TiptapVuetify } from "tiptap-vuetify";
import { addMaseMedia, deleteMase, getAllTags } from "@/util/APIUtils";
import {
  CREATE_MASE_URL,
  UPLOAD_MASE_MEDIA_URL,
  UPDATE_MASE_URL,
} from "@/constants";
import { createMase, editMase, showMessage, showError } from "@/util/MASEUtils";
import Confirm from "@/components/Confirm.vue";
import VueUploadImages from "@/components/upload/Uploader.vue";
import MultiLangText from "@/components/MultiLangText.vue";
// import MaseProblemCart from "./MaseProblemCart.vue";

export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: "NEW",
    },
    masePostDone: {
      type: Function,
      required: false,
    },
  },
  components: { Confirm, VueUploadImages, MultiLangText },
  data() {
    return {
      alreadyExistingTags: ["Haushalt", "Hobbie", "Sport", "Digital"],
      search: null,
      maseProblem: {
        translations: [],
      },
      images: [],
      imagePreviews: [],
      posting: false,
      deleting: false,
      formMode: this.mode,
      editor: [],
    };
  },
  computed: {},
  mounted() {
    document.addEventListener(
      "keyup",
      (e) => {
        this.onKeyPress(e);
      },
      false
    );

    getAllTags().then((response) => {
      if (!response.error) {
        this.alreadyExistingTags = response.data;
      }
    });
  },
  methods: {
    maseLoaded(mase) {
      this.formMode = "EDIT";
      this.maseProblem = mase;
      this.imagePreviews = [];
      if (this.maseProblem.medias) {
        this.maseProblem.medias.forEach((media) => {
          this.images.push({
            name: media.mediaFileName ? media.mediaFileName : "file",
            size: media.mediaSize ? media.mediaSize : "0",
            fromServer: true,
          });
          this.imagePreviews.push(media.mediaUrl);
        });
      }
      console.info("maseSolutionLoaded", mase, this.images, this.imagePreviews);
      this.$nextTick(() => this.$refs.textMultiLang.initLanguage());
      this.$nextTick(() => this.$refs.descriptionMultiLang.initLanguage());
      this.$refs.imageUploader.loadInitialMedia(this.maseProblem.medias);
    },
    onComboChange(e) {
      if (e.length > 5) {
        this.$nextTick(() => this.maseProblem.tags.pop());
      }
      setTimeout(() => this.$refs.hastagCombobox.focus(), 100);
    },
    onInitEditor(e) {
      this.editor = e.editor;
    },
    onKeyPress(e) {
      const ele = document.activeElement;
      if (e.keyCode === 9 && ele.className.includes("tiptap")) {
        this.editor.focus();
      }
    },
    validateFields() {
      console.info(this.$refs.textMultiLang);
      if (
        this.$refs.textMultiLang.isEmpty() ||
        this.$refs.descriptionMultiLang.isEmpty()
      ) {
        showError(this, this.$t("FORM_NO_EMPTY_FIELDS"));
        return false;
      }
      return true;
    },
    reportPostedMase(mase) {
      if (this.masePostDone) {
        this.masePostDone(true, mase);
      } else {
        window.location = "/?success=true";
      }
    },
    addMase() {
      console.info("POST addMase", this.maseProblem);
      if (this.validateFields()) {
        this.posting = true;
        createMase(
          CREATE_MASE_URL,
          UPLOAD_MASE_MEDIA_URL,
          this.maseProblem,
          this.$refs.imageUploader
        )
          .then((newCreatedMase) => {
            console.info("createMase done", newCreatedMase);
            this.reportPostedMase(newCreatedMase);
            this.posting = false;
            showMessage(
              this,
              this.$t("PROBLEM_FORM_ADDED_CONFIRMATION_MESSAGE")
            );
          })
          .catch((e) => {
            showError(this, this.$t("PROBLEM_FORM_ADD_ERROR_MESSAGE"));
            console.warn("failed to post mase solution", e);
            this.posting = false;
          });
      }
    },
    deleteMase() {
      this.$refs.confirm
        .open(
          this.$t("PROBLEM_FORM_DELETE_TITLE"),
          this.$t("PROBLEM_FORM_DELETE_MESSAGE"),
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            this.deleting = true;
            deleteMase(this.maseProblem.id).then((response) => {
              if (!response.error) {
                showMessage(
                  this,
                  this.$t("PROBLEM_FORM_DELETED_CONFIRMATION_MESSAGE")
                );
                this.deleting = false;
                this.reportPostedMase(this.maseProblem);
              } else {
                this.deleting = false;
                showError(this, this.$t("PROBLEM_FORM_DELETED_ERROR_MESSAGE"));
              }
            });
          }
        });
    },
    editMaseProblem() {
      console.info("PUT editMase", this.maseProblem);
      this.posting = true;

      editMase(
        UPDATE_MASE_URL,
        UPLOAD_MASE_MEDIA_URL,
        this.maseProblem,
        this.$refs.imageUploader
      )
        .then((updatedMase) => {
          this.reportPostedMase(updatedMase);
          this.posting = false;
          showMessage(
            this,
            this.$t("PROBLEM_FORM_UPDATED_CONFIRMATION_MESSAGE")
          );
        })
        .catch((e) => {
          showError(
            this,
            this.$t("PROBLEM_FORM_UPDATED_CONFIRMATION_MESSAGE"),
            e
          );
          console.warn("failed to update mase problem", e);
          this.posting = false;
        });
    },
    postMedia(maseId) {
      const promises = [];
      this.images.forEach((media) => {
        if (media && !media.fromServer) {
          promises.push(addMaseMedia(maseId, media));
        }
      });
      Promise.all(promises).then(() => {
        showMessage(this, this.$t("PROBLEM_FORM_UPDATED_CONFIRMATION_MESSAGE"));
        this.posting = false;
        this.$router.push({
          name: "home",
        });
      });
    },
  },
};
</script>
<style lang="css" scoped>
.container {
  max-width: 1200px;
}
.preview {
  max-width: 600px;
}

/deep/ .tiptap-vuetify-editor__content {
  min-height: 300px;
}

/deep/ .ProseMirror {
  min-height: 250px;
}
</style>
