import Vuex from 'vuex';
import Vue from "vue";
import {
    LOCAL_STORAGE_ACCESS_TOKEN,
    LOCAL_STORAGE_LOGGED_IN_USER,
    LOCAL_STORAGE_USERVOTES,
    LOCAL_STORAGE_USERRATES,
    LOCAL_STORAGE_LOCALE,
} from '../constants';

import {
    loadUser,
    getUserIpDetails,
} from './APIUtils';

Vue.use(Vuex);
export function getStoredLocale() {
    return localStorage.getItem(LOCAL_STORAGE_LOCALE);
}
export function setStoredLocale(locale) {
    return localStorage.setItem(LOCAL_STORAGE_LOCALE, locale);
}
export function hasAccessToken() {
    if (localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
        return true;
    }
    return false;
}
export function getAccessToken() {
    return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
}
export function clearAccessToken() {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
}
export function clearLoggedInUser() {
    localStorage.removeItem(LOCAL_STORAGE_LOGGED_IN_USER);
}

export function hasLoggedInUser() {
    if (localStorage.getItem(LOCAL_STORAGE_LOGGED_IN_USER)) {
        return true;
    }
    return false;
}
export function getLoggedInUser() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOGGED_IN_USER));
}

export function setLoggedInUser(user) {
    return localStorage.setItem(LOCAL_STORAGE_LOGGED_IN_USER, JSON.stringify(user));
}
export function setUserrates(userrates) {
    return localStorage.setItem(LOCAL_STORAGE_USERRATES, JSON.stringify(Array.from(userrates.entries())));
}
export function setUservotes(uservotes) {
    return localStorage.setItem(LOCAL_STORAGE_USERVOTES, JSON.stringify(Array.from(uservotes.entries())));
}
export function getUserrates() {
    return new Map(JSON.parse(localStorage.getItem(LOCAL_STORAGE_USERRATES)));
}
export function getUservotes() {
    return new Map(JSON.parse(localStorage.getItem(LOCAL_STORAGE_USERVOTES)));
}

export function extractAccessToken() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    if (token) {
        console.log("extracted token:", token);
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, token);
    }
}

/*
to call a method:   this.$store.commit("logout");
to use a state:     this.$store.state.userLoggedIn
*/
export const store = new Vuex.Store({
    state: {
        isUserLoggedIn: false,
        userLoggedIn: {},
        loading: false,
        userRates: new Map(),
        userVotes: new Map(),
        activeReq: null,
    },
    mutations: {
        logout(state) {
            state.isUserLoggedIn = false;
            clearAccessToken();
            clearLoggedInUser();
            state.userLoggedIn = {};
        },
        loadLogin(state) {
            if (hasAccessToken()) {
                state.isUserLoggedIn = true;
                if (hasLoggedInUser()) {
                    state.userLoggedIn = getLoggedInUser();
                    // console.info("loaded user from store:", state.userLoggedIn.nickname);
                } else {
                    loadUser().then((response) => {
                        if (!response.error) {
                            state.userLoggedIn = response.data;
                            setLoggedInUser(response.data);
                            // console.info("loaded user from api:", state.userLoggedIn.nickname);
                        }
                    });
                }
            }
        },
        loadUserIpDetails(state) {
            getUserIpDetails().then((response) => {
                if (!response.error && response.data) {
                    const uservotesData = response.data.userVotes;
                    uservotesData.forEach((uservote) => {
                        if (uservote.rate) {
                            state.userRates.set(uservote.maseId, uservote.rate);
                        }
                        if (uservote.vote !== null) {
                            state.userVotes.set(uservote.maseId, uservote.vote);
                        }
                    });
                    // console.log("loaded uservotes:", state.userVotes);
                    // console.log("loaded userrates:", state.userRates);
                }
                setUserrates(state.userRates);
                setUservotes(state.userVotes);
            });
        },
        storeRating(state, payload) {
            state.userRates.set(payload.maseId, payload.rate);
            setUserrates(state.userRates);
        },
        storeVote(state, payload) {
            state.userVotes.set(payload.maseId, payload.vote);
            setUservotes(state.userVotes);
        },
        removeVote(state, payload) {
            state.userVotes.delete(payload.maseId);
            setUservotes(state.userVotes);
        },
        loading(state) {
            state.loading = state;
        },
        addRequest(state, req) {
            state.activeReq = { cancel: req.cancel };
        },
        cancelReq(state) {
            state.activeReq.cancel();
        }
    },
    actions: {
        addRequest({ commit }, req) {
            commit("addRequest", req);
        },
        cancelRequest({ commit }) {
            commit("cancelReq");
        },

    }
});
