<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: menu }">
      <v-btn class="ml-1" icon v-on="{ ...menu }">
        <img
          :width="size"
          :src="currentLanguageIcon"
          v-if="currentLanguageIcon"
          :alt="currentLanguageIconText"
        />
        <v-icon :size="size" v-else>fa fa-globe</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="language in availableLanguages"
        :key="language.id"
        @click="$emit('onLanguageChange', language.id)"
      >
        <v-list-item-avatar tile size="24">
          <v-img :size="size" :src="$flagsMap.get(language.id)"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ $t(language.localization) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    selectedLanguageId: String,
    availableLanguages: Array,
    size: {
      type: String,
      required: false,
      default: "38",
    },
  },
  computed: {
    currentLanguageIcon() {
      const found = this.$flagsMap.get(this.selectedLanguageId);
      return found;
    },
    currentLanguageIconText() {
      const found = this.$uiLocalesMap.get(this.selectedLanguageId);
      if (found) {
        return this.$t(found.localization);
      }
      return "";
    },
  },
  methods: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
