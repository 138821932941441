<template>
  <v-main>
    <v-container>
      <v-form class="pt-5">
        <v-card class="mx-auto" max-width="500">
          <v-card-title class="justify-center">Login to Mase Rip</v-card-title>
          <v-card-text class="mx-auto text-center">
            <v-btn x-large :href="goggle" class="red mt-3" width="400px">
              <v-icon large left>fab fa-google</v-icon>
              <v-spacer />
              Login with Google<v-spacer
            /></v-btn>

            <v-btn disabled x-large :href="fb" class="blue mt-3" width="400px">
              <v-icon large left>fab fa-facebook</v-icon>
              <v-spacer />
              Login with Facebook<v-spacer
            /></v-btn>

            <v-btn
              disabled
              x-large
              :href="github"
              class="grey mt-3 mb-5"
              width="400px"
            >
              <v-icon large left>fab fa-github</v-icon>
              <v-spacer />
              Login with Github<v-spacer
            /></v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-container>
  </v-main>
</template>

<script>
import {
  GOOGLE_AUTH_URL,
  FACEBOOK_AUTH_URL,
  GITHUB_AUTH_URL,
} from "@/constants";

export default {
  components: {},
  data() {
    return {
      username: "",
      fb: FACEBOOK_AUTH_URL,
      goggle: GOOGLE_AUTH_URL,
      github: GITHUB_AUTH_URL,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="css" scoped>
.container {
  max-width: 1200px;
}
</style>
