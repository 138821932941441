<template>
  <v-sheet
    @click="$emit('click')"
    flat
    rounded
    :outlined="outlined"
    class="mx-0 my-2"
    style="position: relative; border-color: #ff5252; border-width: medium"
  >
    <v-text-field
      v-if="!multiline"
      v-model="textValue"
      :placeholder="this.$t(placeholder)"
      persistent-hint
      outlined
      dense
      hide-details
      class="text-h6 mb-0 pb-0"
      ref="textField"
    >
      <template v-slot:append class="pa-0">
        <LocaleSwitcher
          class="pt-1"
          size="25"
          :selectedLanguageId="selectedLanguageId"
          :availableLanguages="$apiLocalesList"
          @onLanguageChange="changeLanguage"
        />
      </template>
    </v-text-field>

    <tiptap-vuetify
      v-if="multiline"
      v-model="textValue"
      @init="onInitEditor"
      :placeholder="$t(placeholder)"
      class="flex-grow-1 flex-shrink-0 mx-0 my-0 outlined"
      :card-props="{
        'min-height': '180',
        outlined: false,
        color: $vuetify.theme.dark ? '#121212' : 'white',
        elevation: 2,
      }"
      fill-width
      :extensions="$editorSettings"
      :toolbar-attributes="{
        color: $vuetify.theme.dark ? 'black' : 'grey lighten-4',
        dark: false,
      }"
    >
    </tiptap-vuetify>
    <div
      style="position: absolute; bottom: 2px; right: 6px"
      class=""
      v-if="multiline"
    >
      <LocaleSwitcher
        size="25"
        :selectedLanguageId="selectedLanguageId"
        :availableLanguages="$apiLocalesList"
        @onLanguageChange="changeLanguage"
      />
    </div>
  </v-sheet>
</template>

<style lang="css" scoped></style>

<script>
import { TiptapVuetify } from "tiptap-vuetify";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";

export default {
  components: { TiptapVuetify, LocaleSwitcher },
  data: () => ({
    editor: [],
    selectedValue: null,
  }),
  props: {
    multiline: {
      type: Boolean,
      default: true,
    },
    value: Array,
    placeholder: String,
    fieldName: {
      type: String,
      default: "text",
    },
    initialFocus: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textValue: {
      get() {
        if (!this.selectedValue && this.value) {
          this.loadInitialText();
        }
        if (this.selectedValue) {
          return this.selectedValue[this.fieldName];
        }
        return "loading ...";
      },
      set(newValue) {
        if (this.selectedValue) {
          this.selectedValue[this.fieldName] = newValue;
        }
      },
    },
    selectedLanguageId() {
      if (this.selectedValue) {
        return this.selectedValue.language;
      }
      return this.$apiLocalesDefault.id;
    },
  },
  mounted() {
    console.info("mounted", this.value);
    if (this.initialFocus && this.$refs.textField) {
      this.$nextTick(() => this.$refs.textField.focus());
    }
    if (this.value) {
      this.loadInitialText();
    }

    document.addEventListener(
      "keyup",
      (e) => {
        this.handleTab(e);
      },
      false
    );
  },
  methods: {
    isEmpty() {
      for (let i = 0; i < this.value.length; i += 1) {
        const element = this.value[i];
        if (element[this.fieldName] && element[this.fieldName] !== "<p></p>") {
          return false;
        }
      }
      return true;
    },
    loadInitialText() {
      let initialLocale = this.$i18n.locale;
      if (!this.$apiLocalesMap.has(this.$i18n.locale)) {
        initialLocale = this.$apiLocalesDefault.id;
      }
      const found = this.value.filter((x) => x.language === initialLocale)[0];
      if (found) {
        this.selectedValue = found;
      } else {
        this.selectedValue = this.createNewLanguage(initialLocale);
      }
    },
    createNewLanguage(languageId) {
      const newText = {
        language: languageId,
        title: "",
        description: "",
      };
      this.value.push(newText);
      return newText;
    },
    initLanguage() {
      this.changeLanguage(this.$i18n.locale);
    },
    changeLanguage(id) {
      const found = this.value.filter((x) => x.language === id)[0];
      if (found) {
        this.selectedValue = found;
      } else {
        this.selectedValue = this.createNewLanguage(id);
      }
    },
    onInitEditor(e) {
      this.editor = e.editor;
    },
    handleTab(e) {
      const ele = document.activeElement;
      if (e.keyCode === 9 && ele.className.includes("tiptap")) {
        this.editor.focus();
      }
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  max-width: 1200px;
}
.preview {
  max-width: 600px;
}

/deep/ .tiptap-vuetify-editor__content {
  min-height: 300px;
}

/deep/ .ProseMirror {
  min-height: 250px;
}
</style>
