var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"mx-0 my-2",staticStyle:{"position":"relative","border-color":"#ff5252","border-width":"medium"},attrs:{"flat":"","rounded":"","outlined":_vm.outlined},on:{"click":function($event){return _vm.$emit('click')}}},[(!_vm.multiline)?_c('v-text-field',{ref:"textField",staticClass:"text-h6 mb-0 pb-0",attrs:{"placeholder":this.$t(_vm.placeholder),"persistent-hint":"","outlined":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('LocaleSwitcher',{staticClass:"pt-1",attrs:{"size":"25","selectedLanguageId":_vm.selectedLanguageId,"availableLanguages":_vm.$apiLocalesList},on:{"onLanguageChange":_vm.changeLanguage}})]},proxy:true}],null,false,3734318890),model:{value:(_vm.textValue),callback:function ($$v) {_vm.textValue=$$v},expression:"textValue"}}):_vm._e(),(_vm.multiline)?_c('tiptap-vuetify',{staticClass:"flex-grow-1 flex-shrink-0 mx-0 my-0 outlined",attrs:{"placeholder":_vm.$t(_vm.placeholder),"card-props":{
      'min-height': '180',
      outlined: false,
      color: _vm.$vuetify.theme.dark ? '#121212' : 'white',
      elevation: 2,
    },"fill-width":"","extensions":_vm.$editorSettings,"toolbar-attributes":{
      color: _vm.$vuetify.theme.dark ? 'black' : 'grey lighten-4',
      dark: false,
    }},on:{"init":_vm.onInitEditor},model:{value:(_vm.textValue),callback:function ($$v) {_vm.textValue=$$v},expression:"textValue"}}):_vm._e(),(_vm.multiline)?_c('div',{staticStyle:{"position":"absolute","bottom":"2px","right":"6px"}},[_c('LocaleSwitcher',{attrs:{"size":"25","selectedLanguageId":_vm.selectedLanguageId,"availableLanguages":_vm.$apiLocalesList},on:{"onLanguageChange":_vm.changeLanguage}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }