var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.maseSolution)?_c('div',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0 mr-n3"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('p',{staticClass:"text-body-1 font-weight-normal black--text pa-0 ma-0 pt-3",domProps:{"innerHTML":_vm.maseSolution.description}})]),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isEditable())?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"grey darken-4","elevation":"3","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","size":"10"}},[_vm._v(" fas fa-pencil-alt ")])],1):_vm._e()]}}],null,false,1642624824),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-container',{staticClass:"pa-0 ma-0"},[(_vm.dialog)?_c('MaseSolutionForm',{attrs:{"maseId":_vm.maseId,"initialMaseSolution":_vm.maseSolution,"masePostDone":_vm.masePostDone}}):_vm._e()],1)],1)],1)],1),(_vm.maseSolution.medias)?_c('v-row',{staticClass:"pa-0 ma-0 mx-n3"},_vm._l((_vm.maseSolution.medias),function(image){return _c('v-col',{key:image.id,staticClass:"d-flex child-flex",attrs:{"cols":"auto"}},[_c('v-sheet',{staticClass:"pa-0 ma-0 mb-2",attrs:{"outlined":"","elevation":"2","flat":""}},[(_vm.productUrl)?_c('a',{attrs:{"href":_vm.productUrl,"target":"_blank"}},[(_vm.isImage(image.mediaType))?_c('v-img',{staticClass:"fill-height ma-2",attrs:{"contain":"","src":_vm.getDefaultMediaUrlFromMedia(image)}}):_vm._e()],1):_vm._e(),(!_vm.productUrl && _vm.isImage(image.mediaType))?_c('v-img',{staticClass:"ma-2",attrs:{"contain":"","src":_vm.getDefaultMediaUrlFromMedia(image)}}):_vm._e(),(_vm.isVideo(image.mediaType))?_c('Media',{ref:'video_player',refInFor:true,style:({
            width: '100%',
            height: '100%',
            'background-color': 'black',
          }),attrs:{"kind":'video',"controls":true,"src":[_vm.getDefaultMediaUrlFromMedia(image)],"autoplay":false,"loop":true}}):_vm._e()],1)],1)}),1):_vm._e(),(_vm.maseSolution.product && _vm.maseSolution.product.title)?_c('v-row',{staticClass:"mx-0 mb-3 mt-0"},[_c('v-col',{staticClass:"d-flex child-flex",attrs:{"cols":"auto"}},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.productUrl,"target":"_blank","title":_vm.maseSolution.product.description}},[_c('ProductLine',{attrs:{"product":_vm.maseSolution.product}})],1)])],1):_vm._e(),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-list-item',{staticClass:"grow mt-auto ma-0 pa-0 pb-3"},[_c('v-list-item-content',{staticClass:"pa-0 ma-0"},[_c('v-list-item-title',{staticClass:"text-body-2 font-regular pa-0 ma-0"},[_c('v-list-item-avatar',{staticClass:"ma-2 pa-0 ml-0",attrs:{"size":"25","color":"grey darken-3"}},[_c('v-img',{attrs:{"src":_vm.maseSolution.author.avatarUrl}})],1),_vm._v(_vm._s(_vm.maseSolution.author.nickname)+" "),_c('span',{staticClass:"ml-1 text-body-2 font-weight-light"},[_vm._v("at "+_vm._s(_vm.dateToString(_vm.maseSolution.createdAt))+" ")])],1),_c('v-list-item-title',{staticClass:"pa-0 ma-0 py-1"},[(true)?_c('MaseVote',{attrs:{"mase":_vm.maseSolution,"maseType":"solution"}}):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0 mx-n4"},[(!_vm.last)?_c('v-divider',{staticClass:"ma-0 pa-0 mb-2"}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }