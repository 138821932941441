<template>
  <v-card :loading="loading" v-if="!product.hide" class="box">
    <v-sheet color="brown lighten-5" class="">
      <v-img contain class="" :src="product.image" v-if="product.image">
        <div class="d-flex justify-end pt-3">
          <v-icon size="100px" class="special" aria-hidden="false" color="red"
            >$clickbuy</v-icon
          >
        </div>
      </v-img>
      <v-sheet color="brown lighten-5" class="d-flex justify-center">
        <v-icon v-if="!product.image" color="" size="100px">fa-image</v-icon>
      </v-sheet>
    </v-sheet>

    <v-card-title class="d-flex justify-start">
      <v-sheet min-height="30px" class="d-flex align-center">
        <span class="subtitle-1">{{ product.title }}</span>
      </v-sheet>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  components: {},
  computed: {},
  data() {
    return {};
  },
  props: {
    product: {
      type: Object,
      default() {
        return {
          hide: true,
          title: "",
          image: "",
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="css" scoped>
.box:hover .special {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

.special {
  transform: rotate(40deg);
}

@keyframes shake {
  from {
    transform: scale(1) rotate(40deg);
  }
  to {
    transform: scale(1.2) rotate(35deg);
  }
}
</style>
