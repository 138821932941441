<template>
  <v-main>
    <MaseList :mases="mases" v-if="!loading" />
    <LoadingIndicator v-if="loading" />
  </v-main>
</template>

<script>
import { showError } from "@/util/MASEUtils";
import { getProblemsByQuery } from "../util/APIUtils";
import MaseList from "../components/MaseList.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";

export default {
  components: { MaseList, LoadingIndicator },
  data() {
    return {
      query: this.$route.params.query,
      // mases: 'testdata.mases',
      mases: [],
      loading: true,
      drawer: null,
    };
  },
  methods: {
    fetchMases() {
      this.loading = true;
      getProblemsByQuery(this.query).then((response) => {
        if (!response.error) {
          this.mases = response.data;
          this.loading = false;
        } else {
          showError(this, "Failed to load mases, please retry later!");
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.fetchMases();
  },
};
</script>

<style lang="scss" scoped>
#loading {
  // height: 100%;
}
#loading .md-list {
  // background-color: transparent;
}
</style>
