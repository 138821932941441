<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <v-card
            class="mx-auto my-3 pb-5"
            outlined
            elevation="2"
            v-if="!loading"
          >
            <v-card-title class="mb-0 pb-0 text-h5">
              {{ $t("PROBLEM_FORM_ADD_LABEL") }}
            </v-card-title>
            <v-responsive max-width="1200px">
              <v-container class="my-0 py-0">
                <MaseProblemForm />
              </v-container>
            </v-responsive>
          </v-card>
          <!--
          <v-card
            class="mx-auto my-3 pb-5"
            outlined
            elevation="2"
            v-if="!loading"
          >
            <v-card-title class="text-h5">
              <v-icon color="" large left>fa-lightbulb</v-icon>
              <span class="title mt-1">{{ $t("PROBLEM_FORM_ADD_LABEL") }}</span>
            </v-card-title>
            <v-responsive max-width="1200px">
              <v-container class="ma-0 pa-0">
                <MaseProblemForm />
              </v-container>
            </v-responsive>
          </v-card>
          -->
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import MaseProblemForm from "@/components/form/MaseProblemForm.vue";

export default {
  components: { MaseProblemForm },
  data() {
    return {
      maseData: {
        title: "",
        description: "",
        primaryimage: [],
        primaryimagepreview: null,
        secondaryimage: [],
      },
      // previewmase: { title: "", description: "" },
    };
  },
  computed: {},
  methods: {},
};
</script>
<style lang="css" scoped>
.preview {
  max-width: 600px;
}
</style>
