import Vue from 'vue';
import VueMaterial from 'vue-material';
import VueMeta from 'vue-meta';
import {
  store, extractAccessToken,
} from "@/util/STATEUtils";
import App from './App.vue';
import router from './modules/router';
import vuetify from './modules/vuetify';
import './modules/tiptap';
import i18n from './i18n';
// import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.use(VueMaterial);
Vue.use(VueMeta);

Vue.filter("truncate", (value, length) => {
  if (!value) return "";
  const values = value.toString();
  if (values.length > length) {
    return values.substring(0, length) + "...";
  }
  return values;
});

extractAccessToken();

// Vue.prototype.$config = json.data;
new Vue({
  created() {
  },
  router,
  store,

  //  vuetify,
  i18n,

  vuetify,
  render: (h) => h(App)
}).$mount('#app');

store.commit('loadLogin');
store.commit('loadUserIpDetails');
/*
axios.get(process.env.BASE_URL + "config.json").then((json) => {
  console.log("config response:", json.data);
  Vue.prototype.$config = json.data;
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});
*/
