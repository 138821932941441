import Vue from 'vue';
import Vuetify from 'vuetify';
// import 'vuetify/dist/vuetify.min.css';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader
import ClickIcon from '@/assets/clickBuyButton.vue';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    // theme: { dark: true },
    icons: {
        iconfont: 'fa',
        values: {
            clickbuy: {
                component: ClickIcon,
            },
        },
    },
    options: {
        customProperties: true
    },

    theme: {
        themes: {
            light: {
                background: '#f3f3f3',
            },
            dark: {
                // background: 'f3f3f3',
            },
        },
    },
});

export default vuetify;
