<template>
  <v-main>
    <MaseList :mases="mases" v-if="!loading" :activeTagId="tag" />
    <LoadingIndicator v-if="loading" />
  </v-main>
</template>

<script>
// import axios from "axios";
import { showError } from "@/util/MASEUtils";
import { getTagById } from "../util/APIUtils";
import MaseList from "../components/MaseList.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
// import testdata from "../testdata/testMases.json";

export default {
  components: { MaseList, LoadingIndicator },
  data() {
    return {
      tag: this.$route.params.id,
      // mases: 'testdata.mases',
      mases: [],
      loading: true,
      drawer: null,
    };
  },
  methods: {
    fetchMases() {
      this.loading = true;
      getTagById(this.tag).then((response) => {
        if (!response.error) {
          this.mases = response.data.problems;
          this.loading = false;
        } else {
          showError(this, "Failed to load mases, please retry later!");
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.fetchMases();
  },
};
</script>

<style lang="scss" scoped>
#loading {
  // height: 100%;
}
#loading .md-list {
  // background-color: transparent;
}
</style>
