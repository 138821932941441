var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"px-2",attrs:{"app":"","absolute":""}},[_c('div',{staticClass:"font-weight-black pl-2"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Rip Mase ")]),_c('span',{staticClass:"px-2"},[_vm._v("|")]),_c('a',{attrs:{"href":_vm.$router.resolve({
        name: 'static',
        params: { id: 'privacy-policy' + '-' + this.$i18n.locale },
      }).href}},[_vm._v(_vm._s(_vm.$t("FOOTER_PRIVACY")))]),_c('span',{staticClass:"px-2"},[_vm._v("|")]),_c('a',{attrs:{"href":_vm.$router.resolve({
        name: 'static',
        params: { id: 'imprint' + '-' + this.$i18n.locale },
      }).href}},[_vm._v(_vm._s(_vm.$t("FOOTER_IMPRESSUM")))]),_c('span',{staticClass:"px-2"},[_vm._v("|")]),_c('a',{attrs:{"href":_vm.$router.resolve({
        name: 'static',
        params: { id: 'disclaimer' + '-' + this.$i18n.locale },
      }).href}},[_vm._v(_vm._s(_vm.$t("FOOTER_DISCLAIMER")))]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.onDarkmode()}}},[(!_vm.$vuetify.theme.dark)?_c('v-icon',[_vm._v("fa-moon")]):_vm._e(),(_vm.$vuetify.theme.dark)?_c('v-icon',[_vm._v("fa-sun")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }